import styled, { css, DefaultTheme } from 'styled-components';
import { ColorType } from '@interfaces/styles';
import Text from '@components/text';
import Button from '@components/button';
import { generateScales } from '@helpers/generate-media-queries';
import Input from '@components/input';
import Section from '@components/section';
import { underlineTransition } from '@components/link/link.styled';
import getColorMode, { VariantType } from '@helpers/get-color-mode';

interface HelperProps extends ColorType {
  $isVisible: boolean;
}

export const HelperText = styled.div<HelperProps>`
  ${({ theme, $color, $isVisible }) => css`
    font-family: ${theme.fontFamily.text};
    color: ${theme.colors[$color]};
    ${generateScales(
      'font-size',
      theme.fontSize.extraSmall.mobile,
      theme.fontSize.extraSmall.desktop,
      theme
    )};
    margin-bottom: 0.25rem;
    position: relative;
    ${$isVisible ? `display: block;` : `display: none;`}
    font-style: italic;

    &::before {
      content: '*';
      display: block;
      position: absolute;
      right: 100%;
      top: 0;
      bottom: 0;
      padding-right: 0.125rem;
      font-weight: 600;
    }
  `}
`;

export const ContactSection = styled(Section)`
  margin-top: 5.5rem;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    column-gap: 4rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column;
      row-gap: 4rem;
    }
  `}
`;

export const HelperContainer = styled.div`
  ${({ theme }) => css`
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: none;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: row;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      margin-top: 2rem;
      margin-left: auto;
      margin-right: auto;
      flex-direction: column;
      row-gap: 2.5rem;
    }
  `}
`;

export const FooterContainer = styled.div`
  ${({ theme }) => css`
    display: none;
    flex-direction: row;
    column-gap: 2.5rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: flex;
      column-gap: 2rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      row-gap: 2.5rem;
    }
  `}
`;

export const HelperItemContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    column-gap: 0.75rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column;
      row-gap: 1rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: row;
    }
  `}
`;

export const ImageContainer = styled.div`
  height: 3rem;
  max-width: 3rem;
  width: 100%;
`;

export const TextContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    max-width: 30rem;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      max-width: 22rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      max-width: 100%;
    }
  `}
`;

export const StyledReCaptcha = styled.div`
  display: flex;
  justify-content: center;
`;

export const HelperTextContainer = styled.div`
  ${({ theme }) => css`
    max-width: 19.25rem;
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      max-width: 100%;
    }
  `}
`;

export const StyledTitle = styled(Text)`
  ${({ theme }) => css`
    margin-bottom: 0.5rem;
    ${generateScales(
      'font-size',
      theme.fontSize.heading6.mobile,
      theme.fontSize.body.desktop,
      theme
    )};
    color: ${theme.colors.black};
  `}
`;

export const StyledDescription = styled(Text)`
  ${({ theme }) => css`
    a {
      color: ${theme.colors.secondary};
      ${underlineTransition}
    }
    ${generateScales(
      'font-size',
      theme.fontSize.small.mobile,
      theme.fontSize.small.desktop,
      theme
    )};
  `}
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  row-gap: 2rem;
  max-width: 35rem;
  flex: 1;
`;

export const FormRow = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    row-gap: 2rem;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      grid-template-columns: 1fr;
    }
  `}
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 5rem;
  justify-content: flex-end;
  margin: 1rem 0;
`;

export const StyledText = styled(Text)`
  ${({ theme }) => css`
    margin: 0 auto 0.5rem;
    max-width: 40rem;
    text-align: center;
    font-family: ${theme.fontFamily.display};
    margin-bottom: 0.5rem;
    font-weight: ${theme.fontWeights.normal};
    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.small.desktop, theme)}
  `}
`;

const inputStyle = (theme: DefaultTheme) => css`
  width: 100%;
  padding-left: 0.5rem;

  ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop, theme)};
`;

export const StyledInput = styled(Input)<{ colorMode: VariantType['variant'] }>`
  ${({ theme }) => inputStyle(theme)}

  ${({ colorMode, theme }) => {
    const { fontColor } = getColorMode(colorMode);
    return css`
      border-bottom: 1px solid ${theme.colors[fontColor]};
    `;
  }}
`;

export const TextArea = styled.textarea<{ colorMode: VariantType['variant'] }>`
  ${({ theme }) => inputStyle(theme)}

  ${({ colorMode, theme }) => {
    const { mobile, desktop } = theme.inputSize.lg;
    const { fontColor, accentColor } = getColorMode(colorMode);
    return css`
      background-color: transparent;
      border: 1px solid ${theme.colors[fontColor]};
      /* color: ${theme.colors[accentColor]}; */
      height: 10rem;
      padding: 0.75rem 0.5rem;
      max-height: 10rem;
      outline: none;
      resize: none;
      font-family: ${theme.fontFamily.text};
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize, theme)}

      &::placeholder {
        color: currentColor;
        filter: saturate(50%);
        opacity: 0.65;
      }
    `;
  }}
`;

export const StyledButton = styled(Button)`
  ${({ theme }) => css`
    margin: 0 auto;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      width: 100%;
    }
  `}
`;
