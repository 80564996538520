import React from 'react';
import { RichText } from 'prismic-reactjs';
import { TextComponentProps } from '@components/text/text-props.type';
import { StyledSubheading } from './subheading.styled';

const Subheading = ({
  fontColor = 'primary',
  fontSize = 'subheading',
  richText,
  className,
}: TextComponentProps): JSX.Element => {
  return (
    <StyledSubheading fontSize={fontSize} fontColor={fontColor} className={className}>
      <RichText render={richText} />
    </StyledSubheading>
  );
};

export default Subheading;
