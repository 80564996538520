import styled, { css } from 'styled-components';
import { ColorType, BackgroundColorType, LogoProps } from '@interfaces/styles';
import { generateScales } from '@helpers/generate-media-queries';
import Text from '@components/text';
import { underlineTransition } from '@components/link/link.styled';

export const StyledFooter = styled.footer<BackgroundColorType>`
  ${({ theme, $backgroundColor }) => css`
    position: relative;
    background-color: ${theme.colors[$backgroundColor]};
    color: ${theme.colors.secondaryLight};
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
  `}
`;

export const TopContainer = styled.div<ColorType>`
  ${({ theme, $color }) => css`
    display: flex;
    width: 100%;
    position: relative;
    padding: 8rem 0;
    border-bottom: 1px solid ${theme.colors[$color]};

    @media (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column;
      padding: 4rem 0;
      gap: 4rem;
    }
  `}
`;

export const LogoContainer = styled.div<ColorType>`
  ${({ theme, $color }) => css`
    display: flex;
    flex-direction: column;
    flex: 50%;
    padding-right: 1.5rem;

    @media (max-width: ${theme.breakpoints.lg}) {
      padding-right: 0;
      padding-bottom: 4rem;
      align-items: center;
      border-bottom: 1px solid ${theme.colors[$color]};
    }
  `}
`;

export const Logo = styled.div<LogoProps>`
  ${({ theme, imageUrl, $color }) => css`
    width: 17.5rem;
    height: 7.69rem;
    background-color: ${theme.colors[$color]};
    mask: url(${imageUrl}) no-repeat left center / contain;
    -webkit-mask: url(${imageUrl}) no-repeat left center / contain;
    margin-bottom: ${theme.spacing.md};

    @media (max-width: ${theme.breakpoints.lg}) {
      width: 12.5rem;
    }
  `}
`;

export const LinksContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.375rem;
    max-width: 30rem;
    line-height: 1.5;

    @media (max-width: ${theme.breakpoints.lg}) {
      justify-content: center;
    }
  `}
`;

export const FormContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    flex: 50%;
    padding-left: 1.5rem;

    @media (max-width: ${theme.breakpoints.lg}) {
      padding-left: 0;
      justify-content: center;
      text-align: center;
    }
  `}
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  width: 100%;
`;

export const EmailTitle = styled(Text)`
  margin: 0;
`;

export const EmailDescription = styled(Text)`
  margin: 1.25rem 0 2rem;
`;

export const BottomContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 1;
    padding-bottom: 3rem;
    ${generateScales(
      'font-size',
      theme.fontSize.small.mobile,
      theme.fontSize.small.desktop,
      theme
    )};

    @media (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column;
      row-gap: 1.5rem;
    }
  `}
`;

export const StyledSpan = styled.span<ColorType>`
  ${({ theme, $color }) => css`
    color: ${theme.colors[$color]};
    font-family: ${theme.fontFamily.text};
    font-size: ${theme.fontSize.eyebrow.desktop};
    line-height: ${theme.lineHeights.sm};
    text-align: center;
  `}
`;

export const BottomLinksContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 1.25rem;
    font-size: ${theme.fontSize.extraSmall.desktop};

    @media (max-width: ${theme.breakpoints.md}) {
      margin: 0;
      flex-direction: column;
      row-gap: 1.5rem;
    }
  `}
`;

export const SocialWrapper = styled.div`
  display: flex;
  column-gap: 1.25rem;
`;

export const SocialMedia = styled.a<BackgroundColorType>`
  ${({ theme, $backgroundColor }) => css`
    height: 2.25rem;
    width: 2.25rem;
    border-radius: 1.375rem;
    background-color: ${theme.colors[$backgroundColor]};
    display: flex;
    align-items: center;
    justify-content: center;

    :last-child {
      margin-right: 0;
    }

    img {
      height: 1.125rem;
      width: 1.125rem;
    }
  `}
`;

export const TextButton = styled.button<ColorType>`
  ${({ theme, $color }) => css`
    font-size: 1rem;
    color: ${theme.colors[$color]};
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0 0 5px;
    ${underlineTransition}
  `}
`;
