import styled, { css } from 'styled-components';
import Text from '@components/text';
import Image from '@components/image';
import Section from '@components/section';
import { generateScales } from '@helpers/generate-media-queries';
import Wrapper from '@components/wrapper.styled';
import { startVerticalPosition } from '@helpers/animate';

export const StyledSection = styled(Section)`
  min-height: ${startVerticalPosition}vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
`;

export const StyledWrapper = styled(Wrapper)`
  margin-top: ${100 - startVerticalPosition}vh;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    width: calc(100% - 8rem);
    align-items: center;
    text-align: center;
    position: relative;
    ${generateScales('margin-top', '6rem', '12rem', theme)}
    ${generateScales('margin-bottom', '6rem', '12rem', theme)}

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      width: calc(100% - 8rem);
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      width: 100%;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      width: 100%;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
    }
  `}
`;

export const ImageContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-top: 5rem;
    margin-bottom: 1.25rem;

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      margin-top: 5rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      margin-top: 5rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      margin-top: 3.5rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      margin: 0 0rem;
      margin-bottom: 1rem;
      margin-top: 4.9375rem;
    }
  `}
`;

export const ImageTopContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 -4rem;
    margin-bottom: -6rem;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      margin: 0 -4rem;
      margin-bottom: -5.75rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      margin: 0 -2rem;
      margin-bottom: -5.5rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      margin: 0 -2rem;
      margin-bottom: -3.5rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      margin: 0 0rem;
      margin-bottom: -5.25rem;
    }
  `}
`;

export const StyledFirstImage = styled(Image)`
  ${({ theme }) => css`
    height: 13rem;
    width: auto;

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      /* height: 13rem; */
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      height: 12rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      height: 11rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      height: 10rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      height: 8rem;
      display: none;
    }
  `}
`;

export const StyledSecondImage = styled(StyledFirstImage)`
  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      display: none;
    }
  `}
`;

export const StyledThirdImage = styled(Image)`
  ${({ theme }) => css`
    height: 16.5rem;
    width: auto;

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      height: 16rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      height: 14rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      height: 14rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      height: 11rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      width: 75%;
      height: auto;
    }
  `}
`;

export const BaseTitle = styled(Text)<{ blendMode?: string }>`
  ${({ theme, blendMode = 'normal' }) => css`
    align-items: center;
    margin: 0;
    margin-bottom: -2rem;
    z-index: 4;
    mix-blend-mode: ${blendMode};
    transform: translate3d(0, 0, 0);
    position: relative;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      margin-bottom: -1.25rem;
    }
  `}
`;

export const SpacerTitle = styled(BaseTitle)`
  z-index: 0;
  visibility: hidden;
  pointer-events: none;
  position: relative;
  top: unset;
  left: unset;
  right: unset;
`;

export const BaseSubheading = styled(Text)<{ blendMode: string }>`
  ${({ blendMode }) => css`
    mix-blend-mode: ${blendMode};
    margin: 0;
    display: block;
    text-align: center;
    width: 100%;
    max-width: 64rem;
    position: relative;
    z-index: 4;
  `}
`;
