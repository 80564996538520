import React, { forwardRef } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { RichTextBlock } from 'prismic-reactjs';
import { defaultColorMode } from '@constants/default-values';
import { defaultSettings } from '@constants/carousel';
import generateId from '@helpers/generate-id';
import getColorMode, { VariantType } from '@helpers/get-color-mode';
import { ImageProps } from '@components/image';
import Text from '@components/text';
import { HeadingBlockProps } from '@components/heading-block';
import { transitionClasses } from '@helpers/animate';
import {
  StyledWrapper,
  Container,
  StyledSlider,
  SlickContainer,
  SlickImage,
  StyledText,
  Block,
  StyledHeadingBlock,
  StyledArrow,
  StyledTitle,
  SlideButton,
  StyledSection,
  HorizontalLine,
} from './varied-content.styled';

const VariedContentIdGenerator = generateId();

interface ArrowProps {
  onClick?: () => void;
  isNext?: boolean;
  colorMode: VariantType['variant'];
}

const SlideArrow = ({ onClick, isNext = false, colorMode }: ArrowProps) => {
  const { backgroundColor, accentColor } = getColorMode(colorMode);
  return (
    <SlideButton
      className={`${transitionClasses.color.accentBorder}`}
      onClick={onClick}
      type="button"
      $borderColor={accentColor}
      $isNext={isNext}
      $backgroundColor={backgroundColor}
    >
      <StyledArrow
        stroke={accentColor}
        width={24}
        height={24}
        className={`${transitionClasses.color.accentStroke}`}
      />
    </SlideButton>
  );
};

export interface VariedContentItemProps {
  image?: ImageProps;
  description?: {
    richText: RichTextBlock[];
  };
}

interface VariedContentProps extends HeadingBlockProps {
  align?: string;
  firstRichText?: RichTextBlock[];
  secondRichText?: RichTextBlock[];
  items?: VariedContentItemProps[];
  colorMode?: VariantType['variant'];
  isBordered?: boolean;
}

const VariedContent = forwardRef<HTMLElement, VariedContentProps>(
  (
    {
      eyebrow,
      title,
      firstRichText = [],
      secondRichText = [],
      items = [],
      align,
      colorMode = defaultColorMode,
      isBordered = false,
    },
    ref
  ): JSX.Element | null => {
    const isFirstRichTextPresent = firstRichText?.length > 0 && firstRichText[0].text !== '';
    const isSecondRichTextPresent = secondRichText?.length > 0 && secondRichText[0].text !== '';
    const { backgroundColor, fontColor } = getColorMode(colorMode);

    const settings = {
      ...defaultSettings,
      arrows: true,
      nextArrow: <SlideArrow isNext colorMode={colorMode} />,
      prevArrow: <SlideArrow isNext={false} colorMode={colorMode} />,
      slidesToShow: 2,
      variableWidth: true,
      centerMode: true,
      speed: 500,
      cssEase: 'ease-in-out',
      draggable: true,
      autoplay: true,
      autoplaySpeed: 3000,
      // rtl: align !== 'right' || 'center',
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            centerMode: false,
          },
        },
      ],
    };

    if (
      items?.length === 0 &&
      !items[0].image?.url &&
      !isFirstRichTextPresent &&
      !isSecondRichTextPresent
    ) {
      return null;
    }

    return (
      <StyledSection backgroundColor={backgroundColor} isPaddingBottomDisplayed={false} ref={ref}>
        <StyledWrapper>
          <Block align={align}>
            <StyledHeadingBlock>
              {eyebrow && <Text type="eyebrow" stringText={eyebrow} fontColor={fontColor} />}
              {title && (
                <StyledTitle
                  type="title"
                  stringText={title}
                  fontColor={fontColor}
                  fontSize="heading4"
                  className={transitionClasses.color.accentText}
                />
              )}
            </StyledHeadingBlock>
          </Block>
          <Container $align={align}>
            {isFirstRichTextPresent && (
              <StyledText
                type="subheading"
                richText={firstRichText}
                fontColor={fontColor}
                align={align}
                fontSize="body"
                className={transitionClasses.color.text}
              />
            )}
            <StyledSlider {...settings} $align={align}>
              {items?.map((item) => (
                <SlickContainer key={VariedContentIdGenerator.next().value}>
                  <SlickImage
                    url={item.image?.url}
                    alt={item.image?.alt}
                    gatsbyImageData={item.image?.gatsbyImageData}
                  />
                  {!!item.description?.richText.length && (
                    <Text
                      type="description"
                      richText={item.description?.richText}
                      fontSize="extraSmall"
                      fontColor={fontColor}
                      className={transitionClasses.color.text}
                    />
                  )}
                </SlickContainer>
              ))}
            </StyledSlider>
            {isSecondRichTextPresent && (
              <StyledText
                type="description"
                richText={secondRichText}
                fontColor={fontColor}
                align={align}
                fontSize="body"
                className={transitionClasses.color.text}
              />
            )}
          </Container>
        </StyledWrapper>
        {isBordered && (
          <HorizontalLine $color={fontColor} className={`${transitionClasses.color.border}`} />
        )}
      </StyledSection>
    );
  }
);

export default VariedContent;
