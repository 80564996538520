import React, { forwardRef } from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import { defaultImageSize, defaultColorMode } from '@constants/default-values';
import generateId from '@helpers/generate-id';
import getColorMode, { VariantType } from '@helpers/get-color-mode';
import { ImageProps } from '@components/image';
import Text from '@components/text';
import HeadingBlock, { HeadingBlockProps } from '@components/heading-block';
import Section from '@components/section';
import Wrapper from '@components/wrapper.styled';
import { transitionClasses } from '@helpers/animate';
import {
  StyledGrid,
  ItemWrapper,
  Item,
  ItemImage,
  StyledText,
  ItemText,
  ItemLink,
} from './grid-slice.styled';

const idGenerator = generateId();

export interface GridItemType {
  image?: ImageProps;
  eyebrow?: {
    text: string;
  };
  title?: {
    text: string;
  };
  description?: {
    richText: RichTextBlock[];
  };
  link?: {
    url?: string;
    uid?: string;
  };
  linkLabel?: string;
}

interface GridSliceProps extends HeadingBlockProps {
  itemsPerRow?: number;
  imageSize?: string;
  isShifted?: boolean;
  itemsLayoutType?: string;
  colorMode?: VariantType['variant'];
  items?: GridItemType[];
}

const GridSlice = forwardRef<HTMLElement, GridSliceProps>(
  (
    {
      itemsPerRow = 3,
      imageSize = defaultImageSize,
      itemsLayoutType = 'left',
      isShifted = false,
      colorMode = defaultColorMode,
      eyebrow,
      title,
      subheading,
      items = [],
    },
    ref
  ): JSX.Element | null => {
    const { backgroundColor, fontColor } = getColorMode(colorMode);

    return (
      <Section backgroundColor={backgroundColor} ref={ref}>
        <Wrapper alignment="center" widthSize={itemsPerRow > 2 ? 'lg' : 'md'}>
          <HeadingBlock
            eyebrow={eyebrow}
            title={title}
            subheading={subheading}
            fontColor={fontColor}
          />
          <StyledGrid itemsPerRow={itemsPerRow}>
            {items?.map((item) => {
              return (
                <Item
                  key={idGenerator.next().value}
                  isShifted={isShifted}
                  itemsPerRow={itemsPerRow}
                >
                  <ItemWrapper>
                    <ItemImage
                      gatsbyImageData={item.image?.gatsbyImageData}
                      url={item.image?.url}
                      alt={item.image?.alt}
                      imageSize={imageSize}
                      layoutType={itemsLayoutType}
                    />
                    <ItemText itemsLayoutType={itemsLayoutType}>
                      {item.eyebrow?.text && (
                        <Text
                          type="eyebrow"
                          stringText={item.eyebrow.text}
                          fontSize="small"
                          fontColor={fontColor}
                        />
                      )}
                      {item.title?.text && (
                        <Text
                          type="title"
                          stringText={item.title.text}
                          fontSize="heading5"
                          fontColor={fontColor}
                          className={transitionClasses.color.text}
                        />
                      )}
                      {!!item.description?.richText.length && (
                        <StyledText
                          richText={item.description.richText}
                          fontSize="body"
                          fontColor={fontColor}
                          className={transitionClasses.color.text}
                        />
                      )}
                      {!!item.link?.url && (
                        <ItemLink
                          link={item.link}
                          label={item.linkLabel || 'Learn More'}
                          variant={fontColor}
                          className={transitionClasses.color.text}
                        />
                      )}
                    </ItemText>
                  </ItemWrapper>
                </Item>
              );
            })}
          </StyledGrid>
        </Wrapper>
      </Section>
    );
  }
);

export default GridSlice;
