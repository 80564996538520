import styled, { css } from 'styled-components';
import Text from '@components/text';
import Image from '@components/image';
import Wrapper from '@components/wrapper.styled';
import Section from '@components/section';
import { generateScales } from '@helpers/generate-media-queries';
import { startVerticalPosition } from '@helpers/animate';

export const StyledSection = styled(Section)`
  min-height: ${startVerticalPosition}vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
`;

export const StyledWrapper = styled(Wrapper)`
  ${() => css`
    margin-top: ${100 - startVerticalPosition}vh;
  `}
`;

export const ImageContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    /* margin-top: 6.875rem; */
    margin: 2rem 0;

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      /* margin-top: 6.875rem; */
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      /* margin-top: 6.875rem; */
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      /* margin-top: 6.875rem; */
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      /* margin-top: 6.875rem; */
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      /* margin-top: 6.875rem; */
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: block;
    align-items: center;
    text-align: center;
    position: relative;
    margin: 0 12rem;
    ${generateScales('margin-top', '6rem', '12rem', theme)}
    ${generateScales('margin-bottom', '6rem', '12rem', theme)}

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      margin: 0 2rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      margin: 0 0rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      margin: 0 0rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      margin: 0 0rem;
    }
  `}
`;

export const StyledImage = styled(Image)`
  ${({ theme }) => css`
    width: auto;
    max-height: 22rem;
    margin: 0 0 0rem;
    z-index: 2;
    position: relative;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      height: 20rem;
      /* margin-top: -1.9375rem; */
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      height: 18rem;
      /* margin-top: -1.675rem; */
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      /* margin-top: -1.5rem; */
    }
  `}
`;

export const BaseTitle = styled(Text)<{ blendMode?: string }>`
  ${({ theme, blendMode = 'normal' }) => css`
    position: absolute;
    top: 0;
    left: 0rem;
    right: 0rem;
    z-index: 4;
    text-align: center;
    mix-blend-mode: ${blendMode};
    margin: 0 auto;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      left: 4rem;
      right: 4rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      left: 0rem;
      right: 0rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      left: 0rem;
      right: 0rem;
    }
  `}
`;

export const SpacerTitle = styled(BaseTitle)`
  visibility: hidden;
  position: relative;
  top: unset;
  z-index: 0;
`;

export const BaseSubheading = styled(Text)<{ blendMode?: string }>`
  ${({ theme, blendMode = 'normal' }) => css`
    left: 0rem;
    right: 0rem;
    bottom: 0;
    position: absolute;
    mix-blend-mode: ${blendMode};
    margin: 0px 0 0 0;
    position: absolute;
    display: block;
    text-align: center;
    z-index: 4;
    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      left: 6rem;
      right: 6rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      left: 0rem;
      right: 0rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      left: 0rem;
      right: 0rem;
    }
  `}
`;
export const SpacerSubheading = styled(BaseSubheading)`
  visibility: hidden;
  position: relative;
  bottom: unset;
  left: unset;
  right: unset;
`;
