import React from 'react';
import { GatsbyImage, IGatsbyImageData, GatsbyImageProps } from 'gatsby-plugin-image';
import { StyledImage } from './image.styled';

export interface ImageProps {
  alt?: string;
  url?: string;
  gatsbyImageData?: IGatsbyImageData;
  className?: string;
  objectFit?: GatsbyImageProps['objectFit'];
  loading?: 'eager' | 'lazy';
  data?: object;
}

const Image = ({
  gatsbyImageData,
  url = '',
  alt,
  data = {},
  className = '',
  objectFit,
  loading = 'lazy',
}: ImageProps): JSX.Element => {
  if (gatsbyImageData?.images.fallback?.srcSet) {
    return (
      <GatsbyImage
        image={gatsbyImageData}
        alt={alt || ''}
        className={className}
        objectFit={objectFit}
        loading={loading}
      />
    );
  }
  return <StyledImage src={url} alt={alt} className={className} loading={loading} {...data} />;
};

export default Image;
