import styled, { css } from 'styled-components';
import Text from '@components/text';
import { generateScales } from '@helpers/generate-media-queries';

export const TitleContainer = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    width: 100%;
    padding: 0 2rem 4rem 2rem;
    text-align: center;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      padding: 0 1.5rem 2rem 1.5rem;
    }
  `}
`;

export const StyledTitle = styled(Text)`
  ${({ theme }) => css`
    ${generateScales('font-size', '0.8375rem', '1.25rem', theme)}
    display: block;
    margin-bottom: 0.5rem;
    line-height: 2.13rem;
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin: 0 auto;
    min-height: 0.5rem;
    border-radius: ${theme.borderRadius.small};

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      padding: 0 1rem;
      padding-bottom: 2.5rem;
    }
  `}
`;

export const StyledSubheading = styled(Text)`
  max-width: 960px;
  margin: 0 auto;
  display: block;
`;
