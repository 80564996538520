import React, { forwardRef } from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import { defaultColorMode } from '@constants/default-values';
import generateId from '@helpers/generate-id';
import getColorMode, { VariantType } from '@helpers/get-color-mode';
import Image, { ImageProps } from '@components/image';
import Wrapper from '@components/wrapper.styled';
import Section from '@components/section';
import { transitionClasses } from '@helpers/animate';
import {
  ImageContainer,
  StyledTitle,
  Description,
  StyledLinkComponent,
  TitleContainer,
  Container,
  StyledGrid,
  ContentBlock,
  TextBlock,
  Title,
  StyledSubheading,
  StyledHorizontalLine,
  Block,
} from './two-column-grid.styled';

const twoColumnGridIdGenerator = generateId();

export interface TwoColumnGridridProps {
  title?: string;
  subheading?: RichTextBlock[];
  items: TwoColumnGridItemType[];
  itemsPerRow?: number;
  colorMode?: VariantType['variant'];
}

export interface TwoColumnGridItemType {
  image?: ImageProps;
  title?: {
    text?: string;
  };
  description?: {
    richText: RichTextBlock[];
  };
  link?: {
    uid?: string;
    url?: string;
  };
  link_label?: string;
}

const TwoColumnGrid = forwardRef<HTMLElement, TwoColumnGridridProps>(
  (
    { items, itemsPerRow = 2, title, subheading, colorMode = defaultColorMode },
    ref
  ): JSX.Element => {
    const { backgroundColor, fontColor } = getColorMode(colorMode);

    return (
      <Section backgroundColor={backgroundColor} isPaddingBottomDisplayed={false} ref={ref}>
        <Wrapper>
          <TitleContainer>
            {title && (
              <Title
                type="title"
                tag="h3"
                fontSize="heading3"
                stringText={title}
                fontColor={fontColor}
                className={transitionClasses.color.text}
              />
            )}
            {subheading && (
              <StyledSubheading
                type="subheading"
                richText={subheading}
                fontColor={fontColor}
                fontSize="heading3"
                className={transitionClasses.color.text}
              />
            )}
          </TitleContainer>
        </Wrapper>
        <Block className={`${transitionClasses.color.border}`} $borderColor={fontColor}>
          <Wrapper>
            <StyledGrid itemsPerRow={itemsPerRow}>
              {items?.map((item) => (
                <Container
                  key={twoColumnGridIdGenerator.next().value}
                  $borderColor={fontColor}
                  className={transitionClasses.color.border}
                >
                  <ContentBlock>
                    {item.image?.url && (
                      <ImageContainer>
                        <Image url={item.image?.url} alt={item.image?.alt} />
                      </ImageContainer>
                    )}
                    <TextBlock>
                      <div>
                        {item.title?.text && (
                          <StyledTitle
                            tag="h3"
                            fontColor={fontColor}
                            type="title"
                            fontSize="heading5"
                            stringText={item.title?.text}
                            className={transitionClasses.color.text}
                          />
                        )}
                        {!!item.description?.richText && (
                          <Description
                            type="description"
                            fontSize="body"
                            fontColor={fontColor}
                            richText={item.description?.richText}
                            className={transitionClasses.color.text}
                          />
                        )}
                        {!!item.link?.url && (
                          <StyledLinkComponent
                            label={item.link_label?.toUpperCase() || 'LEARN MORE'}
                            link={item.link}
                            variant={fontColor}
                            className={transitionClasses.color.accentText}
                          />
                        )}
                      </div>
                    </TextBlock>
                  </ContentBlock>
                  <StyledHorizontalLine
                    $color={fontColor}
                    className={transitionClasses.color.border}
                  />
                </Container>
              ))}
            </StyledGrid>
          </Wrapper>
        </Block>
      </Section>
    );
  }
);

export default TwoColumnGrid;
