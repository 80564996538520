import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import Layout from '@layouts/default-layout';
import SEO from '@components/seo';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import renderModule, { ModuleProps } from '@components/module';
import generateId from '@helpers/generate-id';
import { VariantType } from '@helpers/get-color-mode';
import { parallax, buildTransitions } from '@helpers/animate';

gsap.registerPlugin(ScrollTrigger);

const indexPageIdGenerator = generateId();

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export interface MainPageInterface {
  prismicGeneralConfig: {
    data: {
      page_name?: string;
      favicon?: {
        url?: string;
      };
      logo?: {
        alt: string;
        url: string;
      };
    };
  };
  allPrismicPage: {
    nodes: {
      last_publication_date: string;
      data: {
        navbar_color_mode: VariantType['variant'];
        footer_color_mode: VariantType['variant'];
        page_title: {
          text: string;
        };
        meta_description: {
          text: string;
        };
        meta_image: {
          url: string;
          alt: string;
        };
        body: [];
      };
    }[];
  };
}

const IndexPage: React.FC = () => {
  const {
    allPrismicPage,
    prismicGeneralConfig: {
      data: { favicon },
    },
  }: MainPageInterface = useStaticQuery(
    graphql`
      query MainPageQuery {
        prismicGeneralConfig {
          ...GeneralConfigFragment
        }
        allPrismicPage(filter: { uid: { eq: "home" } }) {
          nodes {
            ...PageFragment
          }
        }
      }
    `
  );

  const { page_title, meta_description, meta_image, navbar_color_mode, footer_color_mode } =
    allPrismicPage.nodes[0].data;
  const modules = allPrismicPage.nodes[0].data.body;
  const moduleRefs = useRef<React.RefObject<HTMLElement>[]>(modules.map(() => React.createRef()));

  const ageGate = Cookies.get('age-gate');

  useEffect(() => {
    if (!ageGate) {
      void navigate('/age-gate');
    }
  }, []);

  useEffect(() => {
    const existingInstances = ScrollTrigger.getAll();
    existingInstances.forEach((existingInstance) => existingInstance.kill());
    const slowParallax = parallax();
    const SectionVals = modules.map((module: ModuleProps, index) => {
      const ref = moduleRefs.current[index];
      const nextColorMode = module?.primary?.color_mode as VariantType['variant'];
      const previousModule: ModuleProps = index > 0 ? modules[index - 1] : module;
      const previousColorMode = previousModule.primary.color_mode as VariantType['variant'];
      return { ref, nextColorMode, previousColorMode };
    });
    const newInstances = buildTransitions(SectionVals);

    return () => {
      newInstances.forEach((animationInstance) => animationInstance.kill());
      slowParallax.forEach((animationInstance) => animationInstance.kill());
    };
  }, [moduleRefs]);

  return (
    <Layout navbarColorMode={navbar_color_mode} footerColorMode={footer_color_mode}>
      <SEO
        title={page_title.text}
        faviconHref={favicon?.url || ''}
        description={meta_description.text}
        image={meta_image}
      />
      <Container>
        {modules.map((module, index) => {
          if (module) {
            const currentRef = moduleRefs.current[index];
            return (
              <React.Fragment key={indexPageIdGenerator.next().value}>
                {renderModule(module, currentRef)}
              </React.Fragment>
            );
          }
          return null;
        })}
      </Container>
    </Layout>
  );
};

export default IndexPage;
