import styled, { css } from 'styled-components';
import { generateScales } from '@helpers/generate-media-queries';
import StyledInput from '../input.styled';

const LargeInput = styled(StyledInput)`
  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.lg;

    return css`
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize, theme)}
      ${generateScales('padding-right', mobile.borderRadius, desktop.borderRadius, theme)}
      ${generateScales('height', mobile.height, desktop.height, theme)}
    `;
  }}
`;

export default LargeInput;
