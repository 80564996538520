import React from 'react';
import { RichText } from 'prismic-reactjs';
import { defaultColor } from '@constants/default-values';
import linkResolver from '@helpers/linkResolver';
import { TextComponentProps } from '@components/text/text-props.type';
import { StyledDescription } from './description.styled';

const Description = ({
  fontColor = defaultColor,
  richText,
  fontSize = 'body',
  className,
}: TextComponentProps): JSX.Element => (
  <StyledDescription fontSize={fontSize} fontColor={fontColor} className={className}>
    <RichText render={richText} linkResolver={linkResolver} />
  </StyledDescription>
);

export default Description;
