import styled, { css } from 'styled-components';
import Text from '@components/text';
import Image from '@components/image';
import Section from '@components/section';
import Wrapper from '@components/wrapper.styled';
import { generateScales } from '@helpers/generate-media-queries';
import { startVerticalPosition } from '@helpers/animate';

const contentPadding = 12;

export const StyledWrapper = styled(Wrapper)`
  margin-top: ${100 - startVerticalPosition}vh;
  margin-bottom: ${100 - startVerticalPosition}vh;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    position: relative;
    margin: 0 auto;
    width: 64%;
    align-items: center;
    text-align: center;
    ${generateScales('margin-top', '4rem', '8rem', theme)}
    ${generateScales('margin-bottom', '4rem', '8rem', theme)}

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      width: 60%;
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      width: 75%;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      width: 80%;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      width: 100%;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
    }
  `}
`;

export const ImageContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 5.75rem 0 0rem;
    margin: 0 -4rem;
    column-gap: 4rem;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      margin: 0 -${contentPadding / 3}rem;
      column-gap: 2.5rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      margin: 0 -${contentPadding / 6}rem;
      column-gap: 2rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      padding: 2rem 0 2rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      justify-content: center;
    }
  `}
`;

export const StyledSection = styled(Section)`
  min-height: ${startVerticalPosition}vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

export const StyledFirstImage = styled(Image)`
  ${({ theme }) => css`
    /* margin-top: -4.675rem; */
    height: 18rem;
    width: auto;

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      height: 17rem;
      /* margin-top: -4.5rem; */
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      height: 16rem;
      /* margin-top: -4.25rem; */
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      height: 12rem;
      /* margin-top: -3.9375rem; */
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      margin: 0 auto 0 auto;
      height: 14rem;
      display: none;
    }
  `}
`;

export const StyledSecondImage = styled(StyledFirstImage)`
  ${({ theme }) => css`
    margin: 0;
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      display: block;
      /* margin-top: -3.375rem; */
    }
  `}
`;

export const StyledThirdImage = styled(StyledFirstImage)`
  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      display: none;
    }
  `}
`;

export const BaseTitle = styled(Text)<{ blendMode?: string }>`
  ${({ theme, blendMode = 'normal' }) => css`
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 4;
    margin: 0;
    mix-blend-mode: ${blendMode};
    transform: translate3d(0, 0, 0);

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
    }
  `}
`;

export const SpacerTitle = styled(BaseTitle)`
  z-index: 0;
  visibility: hidden;
  pointer-events: none;
  position: relative;
  top: unset;
  left: unset;
  right: unset;
`;

export const BaseSubheading = styled(Text)<{ blendMode: string }>`
  ${({ theme, blendMode = 'normal' }) => css`
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    position: absolute;
    mix-blend-mode: ${blendMode};
    position: absolute;
    display: block;
    text-align: center;

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
    }
  `}
`;

export const SpacerSubheading = styled(BaseSubheading)`
  ${({ theme }) => css`
    z-index: 0;
    visibility: hidden;
    pointer-events: none;
    position: relative;
    top: unset;
    left: unset !important;
    right: unset !important;

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
    }
  `}
`;
