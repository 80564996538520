import React from 'react';
import { defaultColor } from '@constants/default-values';
import getLinkConfig from '@helpers/get-link-config';
import Arrow from '@components/svg-icons/arrow';
import { defaultTheme } from '@styles/theme.styled';
import {
  StyledLinkWrapper,
  // StyledTextLink,
  // StyledButtonLink,
  StyledButtonLabel,
  ExternalTextLink,
  ExternalButtonLink,
} from './link.styled';

interface LinkProps {
  url?: string;
  uid?: string;
  target?: string;
}

interface TextLinkProps {
  type?: string;
  link: LinkProps;
  label: string;
  className?: string;
  displayArrow?: boolean;
  isAnimated?: boolean;
  variant?: keyof typeof defaultTheme.colors;
}

const LinkComponent: React.FC<TextLinkProps> = ({
  type = 'text',
  link,
  label,
  className = '',
  displayArrow = true,
  variant = defaultColor,
  isAnimated = false,
}) => {
  const arrowSize = 36;
  const linkConfig = getLinkConfig(link);

  const getButtonContent = () => (
    <>
      <StyledButtonLabel>{label}</StyledButtonLabel>
      {displayArrow && <Arrow width={arrowSize} height={arrowSize} stroke={variant} />}
    </>
  );

  if (type === 'text') {
    return linkConfig.isInternal ? (
      // <StyledLinkWrapper variant={variant} className={className}>
      //   <StyledTextLink
      //     to={linkConfig.url}
      //     variant={variant}
      //     $isAnimated={isAnimated}
      //     className={className}
      //   >
      //     {label}
      //   </StyledTextLink>
      // </StyledLinkWrapper>
      <StyledLinkWrapper variant={variant} className={className}>
        <ExternalTextLink
          href={linkConfig.url}
          target={linkConfig.target}
          variant={variant}
          $isAnimated={isAnimated}
          className={className}
        >
          {label}
        </ExternalTextLink>
      </StyledLinkWrapper>
    ) : (
      <StyledLinkWrapper variant={variant} className={className}>
        <ExternalTextLink
          href={linkConfig.url}
          target={linkConfig.target}
          variant={variant}
          $isAnimated={isAnimated}
          className={className}
        >
          {label}
        </ExternalTextLink>
      </StyledLinkWrapper>
    );
  }

  return linkConfig.isInternal ? (
    // <StyledButtonLink to={linkConfig.url} className={className} $arrowSize={arrowSize}>
    //   {getButtonContent()}
    // </StyledButtonLink>
    <ExternalButtonLink href={linkConfig.url} className={className} $arrowSize={arrowSize}>
      {getButtonContent()}
    </ExternalButtonLink>
  ) : (
    <ExternalButtonLink href={linkConfig.url} className={className} $arrowSize={arrowSize}>
      {getButtonContent()}
    </ExternalButtonLink>
  );
};

export default LinkComponent;
