import styled, { css } from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import { generateScales } from '@helpers/generate-media-queries';

export const StyledSection = styled.section<{
  backgroundColor: keyof typeof defaultTheme.colors;
  displayBorder?: boolean;
  isPaddingBottomDisplayed?: boolean;
}>`
  ${({ theme, backgroundColor, isPaddingBottomDisplayed = true }) => css`
    width: 100%;
    position: relative;
    background-color: ${theme.colors[backgroundColor]};
    ${generateScales('padding-top', '3rem', '8rem', theme)}

    &:first-child + section {
      padding-top: 0;
    }
  `};
`;

export const Content = styled.div`
  ${({ theme }) => css`
    z-index: ${theme.zIndex.layeredContent};
    width: 100%;
  `}
`;
