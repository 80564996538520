import React, { forwardRef } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { RichTextBlock } from 'prismic-reactjs';
import { defaultSettings } from '@constants/carousel';
import { defaultColorMode } from '@constants/default-values';
import generateId from '@helpers/generate-id';
import getColorMode, { VariantType } from '@helpers/get-color-mode';
import Section from '@components/section';
import Text from '@components/text';
import HeadingBlock, { HeadingBlockProps } from '@components/heading-block';
import Arrow from '@components/svg-icons/arrow';
import { defaultTheme } from '@styles/theme.styled';
import { transitionClasses } from '@helpers/animate';
import {
  Container,
  SliderContainer,
  StyledText,
  TextContainer,
  SlideButton,
} from './quote-carousel.styled';

const quoteCarouselIdGenerator = generateId();

interface ArrowProps {
  onClick?: () => void;
  isNext?: boolean;
  borderColor: keyof typeof defaultTheme.colors;
  arrowColor: keyof typeof defaultTheme.colors;
}

const SlideArrow = ({ onClick, isNext = false, borderColor, arrowColor }: ArrowProps) => (
  <SlideButton
    onClick={onClick}
    type="button"
    $borderColor={borderColor}
    isNext={isNext}
    className={`${transitionClasses.color.accentBorder}`}
  >
    <Arrow
      stroke={arrowColor}
      width={32}
      height={32}
      className={`${transitionClasses.color.accentStroke}`}
    />
  </SlideButton>
);

export interface QuoteCarouselItemProps {
  quote?: {
    richText: RichTextBlock[];
  };
  author?: {
    richText: RichTextBlock[];
  };
}

interface QuoteCarouselProps extends HeadingBlockProps {
  items?: QuoteCarouselItemProps[];
  colorMode?: VariantType['variant'];
}

const QuoteCarousel = forwardRef<HTMLElement, QuoteCarouselProps>(
  (
    { eyebrow, title, subheading, items = [], colorMode = defaultColorMode },
    ref
  ): JSX.Element | null => {
    if (items?.length === 0 && !title && !subheading && !eyebrow) {
      return null;
    }

    const { backgroundColor, fontColor, accentColor } = getColorMode(colorMode);

    const settings = {
      ...defaultSettings,
      dots: true,
      arrow: true,
      speed: 500,
      slidesToShow: 1,
      autoplay: false,
      nextArrow: <SlideArrow isNext borderColor={accentColor} arrowColor={accentColor} />,
      prevArrow: <SlideArrow borderColor={accentColor} arrowColor={accentColor} />,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
          },
        },
      ],
    };

    return (
      <Section backgroundColor={backgroundColor} ref={ref}>
        <HeadingBlock
          eyebrow={eyebrow}
          title={title}
          subheading={subheading}
          fontColor={fontColor}
        />
        <Container $dotsColor={fontColor}>
          <Slider {...settings}>
            {items?.map((item) => {
              const isSlideVisible = item.quote?.richText.length && item.author?.richText.length;
              return (
                <SliderContainer key={quoteCarouselIdGenerator.next().value}>
                  {isSlideVisible && (
                    <TextContainer>
                      <StyledText
                        type="description"
                        richText={item.quote?.richText}
                        fontSize="large"
                        fontColor={accentColor}
                        colorMode={colorMode}
                        className={transitionClasses.color.text}
                      />
                      <Text
                        type="description"
                        richText={item.author?.richText}
                        fontSize="small"
                        fontColor={accentColor}
                        className={transitionClasses.color.text}
                      />
                    </TextContainer>
                  )}
                </SliderContainer>
              );
            })}
          </Slider>
        </Container>
      </Section>
    );
  }
);

export default QuoteCarousel;
