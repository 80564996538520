/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
 import React from 'react'

 import {
    PrismicPreviewProvider,
    componentResolverFromMap,
  } from 'gatsby-plugin-prismic-previews'
  
  import Page from './src/templates/page.tsx';

  import '@styles/typography.css';
  const { linkResolver } = require('./src/helpers/linkResolve');


  export const wrapRootElement = ({ element }) => (
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
          linkResolver,
          componentResolver: componentResolverFromMap({
            page: Page,
          }),
        },
      ]}
    >
      {element}
    </PrismicPreviewProvider>
  )