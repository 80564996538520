import React, { forwardRef } from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import { defaultColorMode } from '@constants/default-values';
import getColorMode, { VariantType } from '@helpers/get-color-mode';
import Image, { ImageProps } from '@components/image';
import Wrapper from '@components/wrapper.styled';
import Section from '@components/section';
import { transitionClasses } from '@helpers/animate';
import {
  ImageContainer,
  Container,
  TextContainer,
  Title,
  Description,
} from './text-and-image.styled';

interface TextImageProps {
  align?: string;
  colorMode?: VariantType['variant'];
  title?: string;
  description?: RichTextBlock[];
  image?: ImageProps;
}

const TextImage = forwardRef<HTMLElement, TextImageProps>(
  (
    { align, title, description = [], image, colorMode = defaultColorMode },
    ref
  ): JSX.Element | null => {
    const isDescriptionPresent: boolean = description?.length > 0 && description[0].text !== '';
    const { backgroundColor, fontColor } = getColorMode(colorMode);

    if (!title && !image && !isDescriptionPresent) {
      return null;
    }

    return (
      <Section backgroundColor={backgroundColor} ref={ref}>
        <Wrapper>
          <Container align={align}>
            <TextContainer>
              <div>
                {title && (
                  <Title
                    type="title"
                    tag="h3"
                    stringText={title}
                    fontSize="heading4"
                    align={align}
                    fontColor={fontColor}
                    className={transitionClasses.color.accentText}
                  />
                )}
                {isDescriptionPresent && (
                  <Description
                    richText={description}
                    align={align}
                    fontColor={fontColor}
                    fontSize="subheading"
                    className={transitionClasses.color.text}
                  />
                )}
              </div>
            </TextContainer>
            <ImageContainer>
              {(image?.gatsbyImageData || image?.url) && (
                <Image
                  url={image.url}
                  alt={image.alt || ''}
                  gatsbyImageData={image.gatsbyImageData}
                  objectFit="contain"
                />
              )}
            </ImageContainer>
          </Container>
        </Wrapper>
      </Section>
    );
  }
);

export default TextImage;
