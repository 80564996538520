import React, { useState, useRef, Dispatch, useEffect } from 'react';
import { defaultColorMode } from '@constants/default-values';
import { defaultValidate, updateErrors } from '@helpers/validate';
import generateId from '@helpers/generate-id';
import getColorMode, { VariantType } from '@helpers/get-color-mode';
import useOnClickOutside from '@hooks/use-on-click-outside';
import { transitionClasses } from '@helpers/animate';
import { HelperText } from '@components/contact-form/contact-form.shared.styled';
import {
  DropdownContainer,
  Button,
  Text,
  ListItem,
  DropDownList,
  StyledArrow,
  StyledCircle,
} from './select.styled';

interface OptionType {
  option: string;
}

export interface SelectProps {
  placeholder: string;
  items: OptionType[];
  onSelect: (value: string) => void;
  colorMode?: VariantType['variant'];
  name?: string;
  isRequired?: boolean;
  validate?: (value: string) => boolean;
  errors?: string[];
  setErrors?: Dispatch<React.SetStateAction<string[]>>;
}
const selectItemIdGenerator = generateId();

const Select = ({
  name = 'select',
  items,
  onSelect,
  placeholder,
  isRequired = false,
  validate,
  errors = [],
  setErrors,
  colorMode = defaultColorMode,
}: SelectProps): JSX.Element => {
  const { fontColor } = getColorMode(colorMode);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setIsOpen(false));
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [isWarningDisplayed, setIsWarningDisplayed] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const firstUpdate = useRef(true);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const checkIsValid = () => {
    if (isRequired) {
      const options = { value: selectedOption, validate, setIsWarningDisplayed };
      const isSelectValid = defaultValidate(options);
      setIsValid(isSelectValid);
    }
  };

  const onOptionClicked = (value: string) => () => {
    setSelectedOption(value);
    setIsOpen(false);
    onSelect(value);
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (!isOpen) {
      checkIsValid();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isRequired && setErrors) {
      updateErrors(name, isValid, errors, setErrors);
    }
  }, [isValid]);

  return (
    <>
      <HelperText
        className={`${transitionClasses.color.accentText}`}
        $color={fontColor}
        $isVisible={isWarningDisplayed}
      >
        Please select an <u>option</u>
      </HelperText>
      <DropdownContainer
        ref={ref}
        colorMode={colorMode}
        className={`${transitionClasses.color.border} ${transitionClasses.color.text}`}
      >
        <Button
          colorMode={colorMode}
          name={name}
          type="button"
          onClick={toggle}
          isVisible={isOpen}
          isSet={selectedOption !== ''}
          className={`${transitionClasses.color.border} ${transitionClasses.color.text}`}
        >
          {selectedOption || placeholder}
          <StyledCircle>
            <StyledArrow
              isRotated={isOpen}
              stroke={fontColor}
              className={`${transitionClasses.color.stroke}`}
            />
          </StyledCircle>
        </Button>
        <DropDownList
          colorMode={colorMode}
          className={`${transitionClasses.color.border} ${transitionClasses.color.text} ${transitionClasses.color.background}`}
          $isOpen={isOpen}
        >
          {items.map((item) => (
            <ListItem
              colorMode={colorMode}
              onClick={onOptionClicked(item.option)}
              key={selectItemIdGenerator.next().value}
            >
              <Text>{item.option}</Text>
            </ListItem>
          ))}
        </DropDownList>
      </DropdownContainer>
    </>
  );
};

export default Select;
