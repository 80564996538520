import styled, { css } from 'styled-components';
import Text from '@components/text';
import { generateScales } from '@helpers/generate-media-queries';

export const Container = styled.div`
  ${({ theme }) => css`
    text-align: center;
    margin: 0 auto;
    max-width: 64rem;
    ${generateScales('margin-bottom', '3rem', '4rem', theme)}
  `}
`;

export const StyledSubheading = styled(Text)`
  display: block;
  margin-top: 0.5rem;
`;

export const StyledTitle = styled(Text)`
  margin-bottom: 1.25rem;
`;
