import Section from '@components/section';
import styled, { css } from 'styled-components';
import Text from '@components/text';
import { generateScales } from '@helpers/generate-media-queries';

export const StyledSection = styled(Section)`
  min-height: 66vh;
  display: flex;
  align-items: center;
  padding-bottom: 0;
  ${({ theme }) => css`
    ${generateScales('padding-top', '4rem', '12rem', theme)}
  `}
`;

export const StyledText = styled(Text)`
  line-height: 1.375;
  margin-bottom: 0;
`;

export const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 60rem;
  line-height: 1.5;
  display: block;
  align-items: center;
  text-align: center;
  position: relative;
`;
