import React from 'react';
import { defaultTheme } from '@styles/theme.styled';
import { transitionClasses } from '@helpers/animate';
import { StyledSVG, StyledPath } from './svg-icon.styled';

interface ArrowProps {
  stroke?: keyof typeof defaultTheme.colors;
  width?: number;
  height?: number;
  className?: string;
}

const Arrow = ({
  stroke = 'white',
  width = 22,
  height = 14,
  className = transitionClasses.color.stroke,
}: ArrowProps): JSX.Element => (
  <StyledSVG
    $fill="transparent"
    width={width}
    height={height}
    viewBox="0 0 22 14"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    $stroke={stroke}
  >
    <StyledPath
      d="M21 1L11 13L1 1"
      strokeWidth="2"
      strokeLinecap="round"
      vectorEffect="non-scaling-stroke"
    />
  </StyledSVG>
);

export default Arrow;
