interface LinkResolverPropsType {
  uid: string;
  type: string;
}

const linkResolver = (doc: LinkResolverPropsType) => {
  if (doc.uid === 'home') {
    return `/`;
  }

  return `/${doc.uid}`;
};

export default linkResolver;
