import React, { forwardRef } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { RichTextBlock } from 'prismic-reactjs';
import { defaultSettings } from '@constants/carousel';
import { defaultColorMode } from '@constants/default-values';
import generateId from '@helpers/generate-id';
import getColorMode, { VariantType } from '@helpers/get-color-mode';
import { ImageProps } from '@components/image';
import Text from '@components/text';
import HeadingBlock, { HeadingBlockProps } from '@components/heading-block';
import { transitionClasses } from '@helpers/animate';
import {
  Container,
  SlickContainer,
  SlickImage,
  StyledArrow,
  SlideButton,
  StyledSection,
  StyledSlider,
  TextBlock,
} from './photo-carousel.styled';

const photoCarouselIdGenerator = generateId();

interface ArrowProps {
  onClick?: () => void;
  isNext?: boolean;
  colorMode: VariantType['variant'];
}

const SlideArrow = ({ onClick, isNext = false, colorMode }: ArrowProps) => {
  const { accentColor, backgroundColor } = getColorMode(colorMode);
  return (
    <SlideButton
      $isNext={isNext}
      $borderColor={accentColor}
      className={`${transitionClasses.color.accentBorder}`}
      type="button"
      onClick={onClick}
      $backgroundColor={backgroundColor}
    >
      <StyledArrow
        stroke={accentColor}
        width={24}
        height={24}
        className={`${transitionClasses.color.accentStroke}`}
      />
    </SlideButton>
  );
};

export interface PhotoCarouselItemProps {
  image?: ImageProps;
  title?: {
    text: string;
  };
  description?: {
    richText: RichTextBlock[];
  };
  font_color?: 'white' | 'primary' | 'secondary';
}

interface PhotoCarouselProps extends HeadingBlockProps {
  align?: string;
  items?: PhotoCarouselItemProps[];
  colorMode?: VariantType['variant'];
}

const PhotoCarousel = forwardRef<HTMLElement, PhotoCarouselProps>(
  (
    { eyebrow, title, subheading, items = [], align, colorMode = defaultColorMode },
    ref
  ): JSX.Element | null => {
    const { backgroundColor, fontColor } = getColorMode(colorMode);

    const settings = {
      ...defaultSettings,
      arrows: true,
      nextArrow: <SlideArrow isNext colorMode={colorMode} />,
      prevArrow: <SlideArrow isNext={false} colorMode={colorMode} />,
      slidesToShow: 1,
      variableWidth: true,
      centerMode: true,
      speed: 500,
      cssEase: 'ease-in-out',
      draggable: true,
      autoplay: true,
      autoplaySpeed: 3000,
    };
    if (!items?.length || !items[0].image?.url) {
      return null;
    }

    return (
      <StyledSection backgroundColor={backgroundColor} ref={ref}>
        <HeadingBlock
          eyebrow={eyebrow}
          title={title}
          subheading={subheading}
          fontColor={fontColor}
        />
        <Container align={align}>
          <StyledSlider {...settings}>
            {items?.map((item) => (
              <SlickContainer key={photoCarouselIdGenerator.next().value}>
                <SlickImage
                  url={item.image?.url}
                  alt={item.image?.alt}
                  gatsbyImageData={item.image?.gatsbyImageData}
                />
                {(item.title?.text || !!item.description?.richText.length) && (
                  <TextBlock>
                    {item.title?.text && (
                      <Text
                        type="eyebrow"
                        stringText={item.title?.text}
                        fontSize="small"
                        fontColor={item.font_color}
                      />
                    )}
                    {!!item.description?.richText.length && (
                      <Text
                        type="description"
                        richText={item.description?.richText}
                        fontSize="body"
                        fontColor={item.font_color}
                      />
                    )}
                  </TextBlock>
                )}
              </SlickContainer>
            ))}
          </StyledSlider>
        </Container>
      </StyledSection>
    );
  }
);

export default PhotoCarousel;
