import styled, { css } from 'styled-components';

export const StyledGrid = styled.div<{ itemsPerRow?: number; itemWith: number }>`
  ${({ theme, itemsPerRow = 3, itemWith }) => css`
    justify-content: center;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(${itemsPerRow}, 1fr);

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      grid-gap: 2rem;
      grid-template-columns: repeat(auto-fill, minmax(0, ${itemWith}rem));
    }
  `}
`;
