import React from 'react';
import { RichText, RichTextBlock } from 'prismic-reactjs';
import GridSlice from '@components/grid-slice';
import TextImage from '@components/text-and-image';
import ContactFormSection from '@components/contact-form-section';
import PhotoCarousel from '@components/photo-carousel';
import TwoColumnGrid from '@components/two-column-grid';
import VariedContent from '@components/varied-content';
import QuoteCarousel from '@components/quote-carousel';
import Section from '@components/section';
import Wrapper from '@components/wrapper.styled';
import { RichTextContainer } from '@components/rich-text-container/rich-text-container.styled';
import { ImageProps } from '@components/image';
import getColorMode, { VariantType } from '@helpers/get-color-mode';
import HeroComponent from '@helpers/get-hero-component';
import { Item } from './item-props.type';

export interface PrimaryProps {
  eyebrow?: {
    text: string;
  };
  title?: {
    text: string;
  };
  description?: {
    richText: RichTextBlock[];
  };
  first_rich_text?: {
    richText: RichTextBlock[];
  };
  second_rich_text?: {
    richText: RichTextBlock[];
  };
  subheading?: {
    richText: RichTextBlock[];
    text: string;
  };
  image?: ImageProps;
  image_1?: ImageProps;
  image_2?: ImageProps;
  image_3?: ImageProps;
  rich_text: {
    richText?: RichTextBlock[];
  };
  items_in_row?: number;
  image_size?: 'big' | 'small';
  is_shifted?: boolean;
  align?: string;
  items_layout_type?: string;
  image_layout_type?: string;
  campaign_id: string;
  input_placeholder?: string;
  button_label?: string;
  title_size?: string;
  subheading_size?: string;
  color_mode?: VariantType['variant'];
  is_bordered?: boolean;
}

export interface ModuleProps {
  slice_type: string;
  primary: PrimaryProps;
  colorMode?: VariantType['variant'];
  items: Item[];
}

const renderModule = (
  { slice_type, primary, items, colorMode = 'primaryLight' }: ModuleProps,
  currentRef: React.RefObject<HTMLElement>
): JSX.Element | null => {
  const itemsPerRow = primary?.items_in_row ? +primary.items_in_row : 3;
  const { backgroundColor } = getColorMode(colorMode);

  switch (slice_type) {
    case 'page_hero':
      return (
        <HeroComponent
          title={primary.title}
          subheading={primary.subheading}
          image_1={primary.image_1}
          image_2={primary.image_2}
          image_3={primary.image_3}
          image_layout_type={primary.image_layout_type}
          colorMode={primary.color_mode}
          ref={currentRef}
        />
      );
    case 'grid':
      return (
        <GridSlice
          itemsPerRow={itemsPerRow}
          imageSize={primary.image_size}
          isShifted={primary.is_shifted}
          itemsLayoutType={primary.items_layout_type}
          eyebrow={primary.eyebrow?.text}
          title={primary.title?.text}
          subheading={primary.subheading?.richText}
          items={items}
          ref={currentRef}
          colorMode={primary.color_mode}
        />
      );
    case 'text_and_image':
      return (
        <TextImage
          align={primary.align}
          title={primary.title?.text}
          description={primary.description?.richText}
          image={primary.image}
          colorMode={primary.color_mode}
          ref={currentRef}
        />
      );
    case 'rich_text':
      return (
        <Section backgroundColor={backgroundColor} ref={currentRef}>
          <Wrapper>
            <RichTextContainer colorMode={colorMode}>
              <RichText render={primary.rich_text?.richText || []} />
            </RichTextContainer>
          </Wrapper>
        </Section>
      );
    case 'contact_form':
      return (
        <ContactFormSection
          title={primary.title?.text}
          description={primary.description?.richText}
          colorMode={primary.color_mode}
          items={items}
          campaignId={primary.campaign_id || ''}
          ref={currentRef}
        />
      );
    case 'photo_carousel':
      return (
        <PhotoCarousel
          align={primary.align}
          eyebrow={primary.eyebrow?.text}
          title={primary.title?.text}
          subheading={primary.subheading?.richText}
          items={items}
          colorMode={primary.color_mode}
          ref={currentRef}
        />
      );
    case 'two_column_grid':
      return (
        <TwoColumnGrid
          title={primary.title?.text}
          subheading={primary.subheading?.richText}
          items={items}
          colorMode={primary.color_mode}
          ref={currentRef}
        />
      );
    case 'varied_content':
      return (
        <VariedContent
          align={primary.align}
          eyebrow={primary.eyebrow?.text}
          title={primary.title?.text}
          subheading={primary.subheading?.richText}
          firstRichText={primary.first_rich_text?.richText}
          secondRichText={primary.second_rich_text?.richText}
          items={items}
          colorMode={primary.color_mode}
          isBordered={primary.is_bordered}
          ref={currentRef}
        />
      );
    case 'quote_carousel':
      return (
        <QuoteCarousel
          eyebrow={primary.eyebrow?.text}
          title={primary.title?.text}
          subheading={primary.subheading?.richText}
          items={items}
          colorMode={primary.color_mode}
          ref={currentRef}
        />
      );
    default:
      return null;
  }
};

export default renderModule;
