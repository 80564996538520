import { generateScales } from '@helpers/generate-media-queries';
import { defaultTheme } from '@styles/theme.styled';
import Wrapper from '@components/wrapper.styled';
import styled, { css } from 'styled-components';
import Text from '@components/text';

export const StyledWrapper = styled(Wrapper)`
  height: fit-content;
`;

export const TitleContainer = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    width: 100%;
    padding: 0 2rem 4rem 2rem;
    text-align: center;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      padding: 0 1.5rem 2rem 1.5rem;
    }
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin: 0 auto;
    min-height: 0.5rem;
    border-radius: ${theme.borderRadius.small};

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      padding: 0 1rem;
      padding-bottom: 2.5rem;
    }
  `}
`;

export const StyledSubheading = styled(Text)`
  max-width: 960px;
  margin: 0 auto;
  display: block;
`;

export const StyledModal = styled.dialog<{
  isVisible: boolean;
  backgroundColor: keyof typeof defaultTheme.colors;
}>`
  ${({ theme, isVisible, backgroundColor }) => css`
    @keyframes drop {
      from {
        transform: scale(1.125);
        opacity: 0;
        filter: blur(4rem);
      }
      to {
        transform: scale(1);
        opacity: 1;
        filter: blur(0);
      }
    }

    ${isVisible &&
    css`
      animation: 0.5s drop forwards ease-in-out;
    `}

    display: ${isVisible ? 'flex' : 'none'};
    align-items: center;
    position: fixed;
    left: 0;
    z-index: 10;
    width: 100%;
    overflow-y: auto;
    height: 100%;
    ${generateScales('padding-top', '4rem', '12rem', theme)}
    background-color: ${theme.colors[backgroundColor]};

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  `}
`;
