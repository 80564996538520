import React from 'react';
import Title from './title';
import Eyebrow from './eyebrow';
import Subheading from './subheading';
import Description from './description';
import { TextComponentProps } from './text-props.type';

export interface TextProps extends TextComponentProps {
  type?: string;
}

const Text = ({
  type,
  role = undefined,
  tag,
  fontSize,
  fontColor,
  className,
  stringText,
  richText = [],
  id = undefined,
}: TextProps): JSX.Element | null => {
  switch (type) {
    case 'eyebrow':
      return (
        <Eyebrow
          fontSize={fontSize}
          fontColor={fontColor}
          stringText={stringText}
          className={className}
        />
      );

    case 'title':
      return (
        <Title
          tag={tag}
          fontSize={fontSize}
          fontColor={fontColor}
          stringText={stringText}
          className={className}
          id={id}
          role={role}
        />
      );

    case 'subheading':
      return <Subheading fontColor={fontColor} richText={richText} className={className} />;

    default:
      return (
        <Description
          fontSize={fontSize}
          fontColor={fontColor}
          richText={richText}
          className={className}
        />
      );
  }
};

export default Text;
