import React, { useState, ChangeEvent, useEffect } from 'react';
import { navigate } from 'gatsby';
import { defaultColorMode } from '@constants/default-values';
import { validateEmail } from '@helpers/validate';
import { transitionClasses } from '@helpers/animate';
import getColorMode, { VariantType } from '@helpers/get-color-mode';
import Select from '@components/select';
import {
  StyledForm,
  StyledInput,
  FormRow,
  InputContainer,
  TextAreaContainer,
  StyledButton,
  StyledText,
  TextArea,
} from './contact-form.shared.styled';

export interface OptionType {
  option: string;
}

interface ContactFormProps {
  whyOptions: OptionType[];
  colorMode?: VariantType['variant'];
}

// Fill this with correct data from HubSpot
// Add a correct portalId and formId for HubSpot
const portalId = '21261187';
const formId = 'b33b799a-ff7b-425c-b0b2-901b12d1ca29';
const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

const validationSchema = {
  firstName: {
    isRequired: true,
  },
  lastName: {
    isRequired: true,
  },
  email: {
    isRequired: true,
    validate: validateEmail,
  },
  message: {
    isRequired: true,
  },
};

interface ValidationValueType {
  isRequired?: boolean;
  validate?: (value: string) => boolean;
}

const validatedFields: string[] = [];

Object.entries(validationSchema).forEach((item: [string, ValidationValueType]) => {
  if (item[1].isRequired || item[1].validate) {
    validatedFields.push(item[0]);
  }
});

const ContactForm = ({
  whyOptions,
  colorMode = defaultColorMode,
}: ContactFormProps): JSX.Element => {
  const { fontColor } = getColorMode(colorMode);
  const [status, setStatus] = useState('ready');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [contactingReasonDescription, setContactingReasonDescription] = useState('');
  const [contactingReason, setContactingReason] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [errors, setErrors] = useState(validatedFields);

  const handleSubmitForm = () => {
    return new Promise((resolve, reject) => {
      const data = {
        submittedAt: Date.now(),
        fields: [
          {
            name: 'firstname',
            value: firstName,
          },
          {
            name: 'lastname',
            value: lastName,
          },
          {
            name: 'email',
            value: email,
          },
          {
            name: 'message',
            value: `Selected reason: ${contactingReason}; User message: ${contactingReasonDescription}`,
          },
        ],
        context: {
          pageUri: 'subscribe-success',
          pageName: 'Subscribe successful',
        },
      };
      const final_data = JSON.stringify(data);

      const xhr = new XMLHttpRequest();

      xhr.open('POST', url);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else if (xhr.status === 400) {
            setStatus('error');
            reject(new Error(xhr.statusText));
          } else if (xhr.status === 403) {
            setStatus('error');
            reject(new Error(xhr.statusText));
          } else {
            setStatus('error');
            reject(new Error(xhr.statusText));
          }
        }
      };
      xhr.send(final_data);
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmitForm()
      .then(() => navigate('/confirmation'))
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    setIsSubmitDisabled(!!errors.length);
  }, [errors.length]);

  if (status === 'success') {
    return (
      <StyledText
        fontColor={fontColor}
        type="title"
        stringText="Thanks for filling out the form! We'll be in touch shortly"
        className={`${transitionClasses.color.text}`}
      />
    );
  }

  return (
    <>
      {status === 'error' && (
        <StyledText
          fontColor={fontColor}
          type="title"
          stringText="Whoops! Something went wrong. Please try again"
          className={`${transitionClasses.color.text}`}
        />
      )}
      <StyledForm onSubmit={handleSubmit}>
        <FormRow>
          <InputContainer>
            <StyledInput
              name="firstName"
              type="text"
              value={firstName}
              onChange={setFirstName}
              placeholder="First Name"
              isRequired={validationSchema.firstName.isRequired}
              errors={errors}
              setErrors={setErrors}
              colorMode={colorMode}
            />
          </InputContainer>
          <InputContainer>
            <StyledInput
              name="lastName"
              type="text"
              value={lastName}
              onChange={setLastName}
              placeholder="Last Name"
              isRequired={validationSchema.lastName.isRequired}
              errors={errors}
              setErrors={setErrors}
              colorMode={colorMode}
            />
          </InputContainer>
        </FormRow>
        <FormRow>
          <InputContainer>
            <StyledInput
              name="email"
              type="email"
              value={email}
              onChange={setEmail}
              placeholder="Email Address"
              isRequired={validationSchema.email.isRequired}
              validate={validationSchema.email.validate}
              errors={errors}
              setErrors={setErrors}
              colorMode={colorMode}
            />
          </InputContainer>
          {whyOptions.length > 0 && (
            <InputContainer>
              <Select
                name="message"
                items={whyOptions}
                onSelect={setContactingReason}
                placeholder="Reason for Contacting"
                isRequired={validationSchema.message.isRequired}
                errors={errors}
                setErrors={setErrors}
                colorMode={colorMode}
              />
            </InputContainer>
          )}
        </FormRow>
        <TextAreaContainer>
          <TextArea
            value={contactingReasonDescription}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              setContactingReasonDescription(e.currentTarget.value);
            }}
            placeholder="Reason for Contacting"
            colorMode={colorMode}
            className={`${transitionClasses.color.border} ${transitionClasses.color.text}`}
          />
        </TextAreaContainer>
        <StyledButton
          type="submit"
          label="Book now"
          disabled={isSubmitDisabled}
          variant={colorMode}
          outlined
        />
      </StyledForm>
    </>
  );
};

export default ContactForm;
