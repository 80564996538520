import styled, { css } from 'styled-components';
import Image from '@components/image';
import { generateScales } from '@helpers/generate-media-queries';
import { defaultTheme } from '@styles/theme.styled';
import Slider from 'react-slick';
import Section from '@components/section';
import Arrow from '@components/svg-icons/arrow';

const buttonSize = 7;

export const SlickContainer = styled.div`
  position: relative;
  margin: 0 0.75rem;
`;

export const StyledSection = styled(Section)`
  ${({ theme }) => css`
    ${generateScales('padding-top', '3rem', '4rem', theme)}
    ${generateScales('padding-bottom', '3rem', '4rem', theme)}
  `}
`;

export const SlickImage = styled(Image)`
  ${({ theme }) => css`
    ${generateScales('width', '20rem', '45rem', theme)}
    height: auto;
    width: 45rem;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      width: calc(100vw - 2rem);
    }
  `}
`;

export const Container = styled.div<{ align?: string }>`
  margin: 0 auto;
  padding: 0;
  width: 100%;

  .slick-track {
    display: flex;
    ${({ align }) => (align === 'Top' ? `align-items: flex-start;` : `align-items: flex-end;`)}
  }

  .slick-list {
    overflow: hidden;
  }
`;

export const StyledSlider = styled(Slider)`
  margin: 0 auto;
  padding: 0;
  width: calc(100%);

  .slick-list {
    z-index: 2;
  }
`;

export const TextBlock = styled.div`
  ${({ theme }) => css`
    left: 0;
    right: 0;
    position: absolute;
    bottom: 0;
    padding: 4rem 2rem 2rem;
    background: linear-gradient(
      0deg,
      ${theme.colors.secondary} 0%,
      ${theme.colors.secondary}00 100%
    );
  `}
`;

const ArrowSize = 2.5;
const ArrowSizeSmall = 1.5;

export const StyledArrow = styled(Arrow)`
  transform-origin: center;
  width: ${ArrowSize}rem;
  height: ${ArrowSize}em;

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      width: ${ArrowSizeSmall}rem;
      height: ${ArrowSizeSmall}rem;
    }
  `}
`;

const nextButtonStyle = css`
  right: 0;
  transform: translateY(-50%) translateX(calc(50% - ${ArrowSize / 2}rem));

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      transform: translateY(-50%) translateX(calc((50% - ${ArrowSizeSmall / 2}rem)));
    }
  `}

  ${StyledArrow} {
    transform: rotate(-90deg);
  }
`;

const previouButtonStyle = css`
  left: 0;
  transform: translateY(-50%) translateX(calc((50% + ${ArrowSize / 2}rem) - 100%));

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      transform: translateY(-50%) translateX(calc((50% + ${ArrowSizeSmall / 2}rem) - 100%));
    }
  `}

  ${StyledArrow} {
    transform: rotate(90deg);
  }
`;

export const SlideButton = styled.button<{
  $backgroundColor?: keyof typeof defaultTheme.colors;
  $borderColor: keyof typeof defaultTheme.colors;
  $hoverStroke?: keyof typeof defaultTheme.colors;
  $isNext: boolean;
  $blendMode?: string;
}>`
  ${({ theme, $backgroundColor, $borderColor, $isNext, $blendMode = 'normal' }) => css`
    width: ${buttonSize}rem;
    height: ${buttonSize}rem;
    flex-shrink: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    mix-blend-mode: ${$blendMode};
    background: transparent;
    border: 2px solid ${theme.colors[$borderColor]};
    border-radius: 50%;
    cursor: pointer;
    ${$isNext ? nextButtonStyle : previouButtonStyle}

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      width: ${buttonSize / 1.25}rem;
      height: ${buttonSize / 1.25}rem;
    }

    &:hover {
      background-color: ${theme.colors[$borderColor]};
      mix-blend-mode: normal !important;

      ${StyledArrow} {
        ${$backgroundColor && `stroke: ${theme.colors[$backgroundColor]} !important;`}
      }
    }
  `}
`;

export const BaseButton = styled(SlideButton)`
  pointer-events: none;
  z-index: 1;
`;

export const MiddleButton = styled(SlideButton)`
  pointer-events: none;
  z-index: 4;
`;
