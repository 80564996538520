import React, { forwardRef } from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import { defaultColorMode } from '@constants/default-values';
import getColorMode, { VariantType } from '@helpers/get-color-mode';
import Wrapper from '@components/wrapper.styled';
import Text from '@components/text';
import { StyledText, Content, StyledSection } from './text-page-hero.styled';

export interface TextPageHeroProps {
  title?: string;
  subheading?: RichTextBlock[];
  colorMode?: VariantType['variant'];
}

const TextPageHero = forwardRef<HTMLElement, TextPageHeroProps>(
  (
    { title, subheading = [], colorMode = defaultColorMode }: TextPageHeroProps,
    ref
  ): JSX.Element | null => {
    if (!title && !subheading) {
      return null;
    }
    const { backgroundColor, fontColor } = getColorMode(colorMode);
    return (
      <StyledSection backgroundColor={backgroundColor} ref={ref}>
        <Wrapper>
          <Content>
            {title && (
              <StyledText
                type="title"
                tag="h1"
                stringText={title}
                fontColor={fontColor}
                fontSize="heading3"
              />
            )}
            {subheading.length > 0 && (
              <Text
                type="subheading"
                richText={subheading}
                fontColor={fontColor}
                fontSize="large"
              />
            )}
          </Content>
        </Wrapper>
      </StyledSection>
    );
  }
);

export default TextPageHero;
