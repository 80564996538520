import styled, { css } from 'styled-components';
import Text from '@components/text';
import Grid from '@components/grid';
import LinkComponent from '@components/link';
import { defaultTheme } from '@styles/theme.styled';
import { HorizontalLine } from '@components/varied-content/varied-content.styled';

export const Block = styled.div<{ $borderColor: keyof typeof defaultTheme.colors }>`
  ${({ theme, $borderColor }) => css`
    border-top: 1px solid ${theme.colors[$borderColor]};
    border-bottom: 1px solid ${theme.colors[$borderColor]};
    margin: 4rem 0 0 0;
  `}
`;

export const ContentBlock = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 30rem;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      max-width: 26rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      max-width: 18rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      max-width: 14rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      max-width: 100%;
    }
  `}
`;

export const TextBlock = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 26rem;
    margin: 0 auto;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      max-width: 24rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      max-width: 20rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      max-width: 16rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      max-width: 100%;
    }
  `}
`;

export const StyledGrid = styled(Grid)`
  display: flex;
  grid-gap: 0;
  flex-wrap: wrap;
  text-align: center;
  width: 100%;
`;

export const StyledHorizontalLine = styled(HorizontalLine)`
  display: none;
`;

export const StyledTitle = styled(Text)`
  margin: 1.25rem 0 1rem 0;
  display: block;
`;

export const Description = styled(Text)`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.text};
    font-size: 1rem;
    font-weight: ${theme.fontWeights.normal};
    line-height: ${theme.lineHeights.lg};
  `}
`;

export const Title = styled(Text)`
  margin: 0 auto 1rem;
  padding: 0 1rem;
  text-align: center;
`;

export const StyledSubheading = styled(Text)`
  display: block;
  text-align: center;
  padding: 0 1rem;
`;

export const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
`;

export const StyledLinkComponent = styled(LinkComponent)`
  margin-top: 2rem;
`;

export const TitleContainer = styled.div`
  max-width: 64rem;
  margin: 0 auto;
`;

export const Container = styled.div<{ $borderColor: keyof typeof defaultTheme.colors }>`
  ${({ theme, $borderColor }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 50%;
    padding: 4rem 4rem 4rem 0;
    align-items: flex-end;

    :nth-child(even) {
      align-items: flex-start;
      padding: 4rem 0rem 0rem 4rem;
      border-left: 1px solid ${theme.colors[$borderColor]};
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      display: block;
      max-width: 100%;

      :nth-child(even) {
        border: none;
        padding: 4rem 0rem 4rem 0rem;
      }

      :nth-child(odd) {
        padding: 4rem 0 0;

        @media only screen and (max-width: ${theme.breakpoints.sm}) {
          &:first-child {
            border-bottom: 1px solid transparent;
            padding: 4rem 0;
          }
        }

        ${StyledHorizontalLine} {
          display: block;
          margin: 4rem 0 0;
        }
      }
    }
  `}
`;
