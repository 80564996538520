import styled, { css } from 'styled-components';
import { BackgroundColorType, LogoProps } from '@interfaces/styles';
import { hexToRgba } from '@helpers/hex-to-rgba';
import { VariantType } from '@helpers/get-color-mode';
import { defaultTheme } from '@styles/theme.styled';
import Button from '@components/button';
import { generateScales } from '@helpers/generate-media-queries';
import Wrapper from '@components/wrapper.styled';

export const headerHeight = '8rem';

export const MainContainer = styled.header`
  top: 0;
  z-index: 12;
  width: 100%;
  position: fixed;
`;

export const StyledLink = styled.a<{ $fontColor: keyof typeof defaultTheme.colors }>`
  ${({ theme, $fontColor }) => {
    const { mobile, desktop } = theme.inputSize.md;

    return css`
      display: inline;
      line-height: 1.5rem;
      font-weight: 400;
      font-family: ${theme.fontFamily.text};
      text-transform: uppercase;
      text-underline-offset: 0.125rem;
      text-decoration-thickness: 1.5px;
      color: ${theme.colors[$fontColor]};
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize, theme)};

      &:hover {
        text-decoration: none;
      }
    `;
  }}
`;

export const HeaderWrapper = styled(Wrapper)`
  ${({ theme }) => css`
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
    /* width: 100%;
    max-width: 95.5rem;
    margin: 0 auto; */
    /* padding: 1rem 7.25rem 4rem; */
    padding-top: 1rem;
    padding-bottom: 2rem;
    z-index: 12;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      padding-top: 1rem;
      padding-bottom: 2rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      padding-top: 1rem;
      padding-bottom: 2rem;
    }
  `}
`;

export const HeaderBackgroundColor = styled.div<BackgroundColorType>`
  ${({ theme, $backgroundColor }) => css`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      ${hexToRgba(theme.colors[$backgroundColor], 1.0)} 25%,
      ${hexToRgba(theme.colors[$backgroundColor], 0.85)} 60%,
      ${hexToRgba(theme.colors[$backgroundColor], 0)}
    );
    z-index: 11;
  `}
`;

interface StyledHeaderProps {
  $isActive: boolean;
  colorMode: VariantType['variant'];
}

export const StyledHeader = styled.nav<StyledHeaderProps>`
  ${({ theme, $isActive }) => {
    return css`
      display: flex;
      padding: 0;
      align-items: center;
      width: 100%;
      min-height: ${headerHeight};
      max-height: max-content;
      position: absolute;
      top: 0;
      z-index: ${theme.zIndex.navbar};

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        min-height: 5rem;
      }

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        min-height: 4rem;
      }

      ${$isActive &&
      css`
        transition-delay: 0s;
      `}
    `;
  }}
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Logo = styled.div<LogoProps>`
  ${({ theme, imageUrl, $color }) => css`
    width: 8rem;
    height: 3.5rem;
    background-color: ${theme.colors[$color]};
    mask: url(${imageUrl}) no-repeat center / contain;
    -webkit-mask: url(${imageUrl}) no-repeat center / contain;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      width: 7.5rem;
      height: 3rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      width: 6.375rem;
      height: 2.675rem;
    }
  `}
`;

export const Menu = styled.ul`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    column-gap: 1.25rem;
    align-items: center;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      column-gap: 1rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      column-gap: 1.25rem;
      display: none;
    }
  `}
`;

export const StyledButton = styled(Button)`
  ${({ theme }) => css`
    min-width: 6.25rem;
    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: none;
    }
  `}
`;
