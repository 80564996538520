import styled, { css } from 'styled-components';
import { ColorType } from '@interfaces/styles';
import getColorMode, { VariantType } from '@helpers/get-color-mode';
import Button from '@components/button';

const linePxWidth = 2;

export const Line = styled.div<ColorType>`
  ${({ theme, $color }) => css`
    width: 100%;
    height: ${linePxWidth}px;
    background: ${theme.colors[$color]};
    border-radius: ${linePxWidth / 2}px;
    position: relative;
    transform-origin: center;
  `}
`;

interface BurgerButtonProps {
  $isActive: boolean;
}

export const BurgerButton = styled.div<BurgerButtonProps>`
  display: none;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    ${({ $isActive }) => css`
      position: relative;
      width: 2.75rem;
      height: 2.5rem;
      padding: 0.5rem;
      border-radius: 100%;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      ${$isActive &&
      css`
        ${Line} {
          :nth-child(1) {
            transform: translateY(0.5rem) rotate(45deg);
          }

          :nth-child(2) {
            opacity: 0;
            transform: translateX(20px);
          }

          :nth-child(3) {
            transform: translateY(-0.5rem) rotate(-45deg);
          }
        }
      `}
    `}
  }
`;

interface MobileContentProps {
  isVisible?: boolean;
  headerHeight?: number;
  colorMode: VariantType['variant'];
}

export const MobileContent = styled.ul<MobileContentProps>`
  ${({ theme, isVisible, headerHeight = 118, colorMode }) => {
    const { backgroundColor } = getColorMode(colorMode);

    return css`
      display: none;
      padding: ${headerHeight}px 0 2rem 0;
      margin: 0;
      opacity: 0;
      top: 0;
      transform: translateY(-100%);
      background: ${theme.colors[backgroundColor]};
      filter: drop-shadow(${theme.shadows.normal});
      transition: transform ${theme.transitions.normal}, visibility,
        opacity ${theme.transitions.long};
      position: absolute;
      width: 100%;
      z-index: ${theme.zIndex.navbar - 1};
      visibility: hidden;
      border-bottom-left-radius: ${theme.borderRadius.normal};
      border-bottom-right-radius: ${theme.borderRadius.normal};
      max-height: calc(100vh - 4rem);
      overflow-y: scroll;
      ::-webkit-scrollbar {
        display: none;
      }

      ${isVisible &&
      css`
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
      `};

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        display: initial;
      }
    `;
  }}
`;

export const MobileMenu = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`;

export const StyledMobileButton = styled(Button)`
  margin-top: 1.25rem;
`;
