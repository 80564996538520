import styled from 'styled-components';

const Container = styled.div`
  overflow-x: hidden;

  > *:first-child:not(header):not(nav) {
    margin-top: 5rem;
  }
`;

const Content = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.primary};
  `}
`;

export { Container, Content };
