import styled, { css } from 'styled-components';
import Slider from 'react-slick';
import Image from '@components/image';
import { generateScales } from '@helpers/generate-media-queries';
import Text from '@components/text';
import { ColorType } from 'interfaces/styles';
import Section from '@components/section';
import { defaultTheme } from '@styles/theme.styled';
import Arrow from '@components/svg-icons/arrow';
import Wrapper from '@components/wrapper.styled';

const buttonSize = 7;
const defaultAlign = 'center';

export const StyledWrapper = styled(Wrapper)`
  ${({ theme }) => css`
    ${generateScales('padding-bottom', '4rem', '6rem', theme)};
  `}
`;

export const StyledSection = styled(Section)`
  ${({ theme }) => css`
    ${generateScales('padding-top', '4rem', '6rem', theme)};
  `}
`;

export const SlickContainer = styled.div`
  ${({ theme }) => css`
    padding: 0 1rem;
    width: 100%;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      padding: 0 0.5rem;
    }
  `}
`;

export const SlickImage = styled(Image)`
  width: auto;
  height: 24rem;
  margin-bottom: 0;
`;

export const StyledHeadingBlock = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 36rem;
    margin: 0 0 0rem 0;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      max-width: 28rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      max-width: unset;
    }
  `}
`;

export const StyledTitle = styled(Text)`
  ${({ theme }) => css`
    ${generateScales('margin-bottom', '2rem', '1rem', theme)}
  `}
`;

export const StyledText = styled(Text)<{ align?: string }>`
  ${({ theme, align = defaultAlign }) => css`
    width: 100%;
    max-width: 36rem;
    display: inline-block;
    align-self: ${align === 'center' ? align : `flex-${align}`};
    ${generateScales('font-size', '1rem', '1.13rem', theme)}

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      max-width: 28rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      max-width: unset;
    }
  `}
`;

export const Block = styled.div<{ align?: string }>`
  ${({ align }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;

    ${StyledHeadingBlock} {
      ${align === 'center'
        ? css`
            text-align: center;
            align-self: center;
          `
        : css`
            text-align: left;
            align-self: flex-${align};
          `};
    }
  `}
`;

export const Container = styled.div<{ $align?: string }>`
  ${({ theme, $align = defaultAlign }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 0 0 0;
    align-items: ${$align === 'center' ? $align : `flex-${$align}`};

    @media (max-width: ${theme.breakpoints.md}) {
      align-items: center;
    }
  `}

  .slick-track {
    display: flex;
  }
`;

export const StyledSlider = styled(Slider)<{ $align?: string }>`
  margin: 2.5rem 0 0 0;

  ${({ $align = defaultAlign }) =>
    $align === 'center'
      ? css`
          width: calc(100% - 6rem);
        `
      : css`
          width: 100%;
          position: relative;
        `}

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      width: 100%;
      margin: 2.5rem 1rem 0;
    }

    .slick-list {
      z-index: 2;
    }

    + ${StyledText} {
      margin-top: 2.5rem;

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        margin-top: 2.5;
      }
    }
  `}
`;

export const HorizontalLine = styled.hr<ColorType>`
  ${({ theme, $color }) => css`
    border: 0;
    border-top: 1px solid ${theme.colors[$color]};
    display: block;
    height: 1px;
    margin: 0rem 0;
    padding: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  `}
`;

const ArrowSize = 2;
const ArrowSizeSmall = 1.5;

export const StyledArrow = styled(Arrow)`
  transform-origin: center;
  width: ${ArrowSize}rem;
  height: ${ArrowSize}em;

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      width: ${ArrowSizeSmall}rem;
      height: ${ArrowSizeSmall}rem;
    }
  `}
`;

const nextButtonStyle = css`
  right: 0;
  transform: translateY(-50%) translateX(75%);

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      transform: translateY(-50%) translateX(calc((75% - ${ArrowSizeSmall / 2}rem)));
    }
  `}

  ${StyledArrow} {
    transform: rotate(-90deg);
  }
`;

const previouButtonStyle = css`
  left: 0;
  transform: translateY(-50%) translateX(-75%);

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      transform: translateY(-50%) translateX(calc((25% + ${ArrowSizeSmall / 2}rem) - 100%));
    }
  `}

  ${StyledArrow} {
    transform: rotate(90deg);
  }
`;

export const SlideButton = styled.button<{
  $backgroundColor?: keyof typeof defaultTheme.colors;
  $borderColor: keyof typeof defaultTheme.colors;
  $hoverStroke?: keyof typeof defaultTheme.colors;
  $isNext: boolean;
  $blendMode?: string;
}>`
  ${({ theme, $backgroundColor, $borderColor, $isNext, $blendMode = 'normal' }) => css`
    width: ${buttonSize}rem;
    height: ${buttonSize}rem;
    flex-shrink: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    mix-blend-mode: ${$blendMode};
    background: transparent !important;
    border: 2px solid ${theme.colors[$borderColor]};
    border-radius: 50%;
    cursor: pointer;
    ${$isNext ? nextButtonStyle : previouButtonStyle}

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      width: ${buttonSize / 1.25}rem;
      height: ${buttonSize / 1.25}rem;
    }

    &:hover {
      background-color: ${theme.colors[$borderColor]} !important;
      mix-blend-mode: normal !important;

      ${StyledArrow} {
        ${$backgroundColor && `stroke: ${theme.colors[$backgroundColor]} !important;`}
      }
    }
  `}
`;

export const BaseButton = styled(SlideButton)`
  pointer-events: none;
  z-index: 1;
`;

export const MiddleButton = styled(SlideButton)`
  pointer-events: none;
  z-index: 4;
`;
