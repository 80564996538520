import React, { ReactNode, forwardRef } from 'react';
import { defaultTheme } from '@styles/theme.styled';
import { StyledSection, Content } from './section.styled';

interface SectionProperties {
  children: ReactNode;
  backgroundColor: keyof typeof defaultTheme.colors;
  isPaddingBottomDisplayed?: boolean;
  className?: string;
  id?: string;
}

const Section = forwardRef<HTMLElement, SectionProperties>(
  (
    {
      id = '',
      className = '',
      children,
      backgroundColor = 'white',
      isPaddingBottomDisplayed = true,
    }: SectionProperties,
    ref
  ): JSX.Element => {
    return (
      <StyledSection
        backgroundColor={backgroundColor}
        isPaddingBottomDisplayed={isPaddingBottomDisplayed}
        className={className}
        id={id}
        ref={ref}
      >
        <Content>{children}</Content>
      </StyledSection>
    );
  }
);

export default Section;
