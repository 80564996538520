import styled, { css } from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import { generateScales } from '@helpers/generate-media-queries';
import { createTextLinkCSS } from '@components/link/link.styled';

export const StyledDescription = styled.div<{
  fontColor: keyof typeof defaultTheme.colors;
  fontSize: keyof typeof defaultTheme.fontSize;
}>`
  ${({ theme, fontSize, fontColor = 'primary' }) => {
    return css`
      font-family: ${theme.fontFamily.text};
      font-size: ${fontSize as string};
      line-height: ${theme.lineHeights.lg};
      color: ${theme.colors[fontColor]};
      opacity: 0.7;
      filter: grayscale(0.75);

      ${generateScales(
        'font-size',
        theme.fontSize[fontSize].desktop,
        theme.fontSize[fontSize].desktop,
        theme
      )}

      a {
        ${() => createTextLinkCSS(theme, fontColor)}

        &::after {
          display: none;
        }
      }
    `;
  }}
`;
