import ComplexPageHero from '@components/hero-section/complex-page-hero';
import { defaultColorMode } from '@constants/default-values';
import { ImageProps } from '@components/image';
import PageHero from '@components/hero-section/main-page-hero';
import TextPageHero from '@components/hero-section/text-page-hero';
import TwoImagesPageHero from '@components/hero-section/two-images-page-hero';
import { RichTextBlock } from 'prismic-reactjs';
import React, { forwardRef } from 'react';
import { VariantType } from './get-color-mode';

interface PageHeroPrimaryProps {
  title?: {
    text: string;
  };
  subheading?: {
    richText: RichTextBlock[];
    text: string;
  };
  image_1?: ImageProps;
  image_2?: ImageProps;
  image_3?: ImageProps;
  image_layout_type?: string;
  colorMode?: VariantType['variant'];
}

const HeroComponent = forwardRef<HTMLElement, PageHeroPrimaryProps>(
  (
    {
      title,
      subheading,
      image_1,
      image_2,
      image_3,
      image_layout_type,
      colorMode = defaultColorMode,
    }: PageHeroPrimaryProps,
    ref
  ): JSX.Element | null => {
    switch (image_layout_type) {
      case 'none':
        return (
          <TextPageHero
            title={title?.text}
            subheading={subheading?.richText}
            colorMode={colorMode}
            ref={ref}
          />
        );
      case 'one':
        return (
          <PageHero
            title={title?.text}
            subheading={subheading?.richText}
            image={image_1}
            colorMode={colorMode}
            ref={ref}
          />
        );
      case 'two':
        return (
          <TwoImagesPageHero
            title={title?.text}
            subheading={subheading?.richText}
            firstImage={image_1}
            secondImage={image_2}
            thirdImage={image_3}
            colorMode={colorMode}
            ref={ref}
          />
        );
      case 'three':
        return (
          <ComplexPageHero
            title={title?.text}
            subheading={subheading?.richText}
            firstImage={image_1}
            secondImage={image_2}
            thirdImage={image_3}
            colorMode={colorMode}
            ref={ref}
          />
        );
      default:
        return (
          <TextPageHero
            title={title?.text}
            subheading={subheading?.richText}
            colorMode={colorMode}
            ref={ref}
          />
        );
    }
  }
);

export default HeroComponent;
