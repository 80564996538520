import React, { forwardRef } from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import { defaultColorMode } from '@constants/default-values';
import getColorMode, { VariantType } from '@helpers/get-color-mode';
import { ImageProps } from '@components/image';
import { transitionClasses } from '@helpers/animate';
import {
  Content,
  ImageContent,
  StyledImage,
  BaseTitle,
  SpacerTitle,
  SpacerSubheading,
  BaseSubheading,
  StyledSection,
  StyledWrapper,
} from './main-page-hero.styled';

export interface PageHeroProps {
  title?: string;
  subheading?: RichTextBlock[];
  colorMode?: VariantType['variant'];
  image?: ImageProps;
}

const PageHero = forwardRef<HTMLElement, PageHeroProps>(
  (
    { title, subheading = [], colorMode = defaultColorMode, image }: PageHeroProps,
    ref
  ): JSX.Element | null => {
    if (!title && !subheading) {
      return null;
    }
    const { backgroundColor, fontColor } = getColorMode(colorMode);

    return (
      <StyledSection backgroundColor={backgroundColor} ref={ref}>
        <StyledWrapper>
          <Content>
            {title && (
              <>
                <SpacerTitle
                  type="title"
                  tag="h1"
                  stringText={title}
                  fontColor={fontColor}
                  fontSize="heading1"
                  data-nosnippet
                  aria-hidden="true"
                />
                <BaseTitle
                  type="title"
                  tag="h1"
                  stringText={title}
                  fontColor={fontColor}
                  fontSize="heading1"
                  data-nosnippet
                  aria-hidden="true"
                  className={`${transitionClasses.color.text}`}
                />
              </>
            )}
            <ImageContent id="parallax-image">
              <StyledImage
                url={image?.url}
                alt={image?.alt}
                className={transitionClasses.parallax}
                data={{
                  'data-parallax-offset': 1.25,
                  'data-parallax-trigger-id': 'parallax-image',
                }}
              />
            </ImageContent>
            {subheading.length > 0 && (
              <>
                <SpacerSubheading
                  type="subheading"
                  blendMode="normal"
                  richText={subheading}
                  fontColor={fontColor}
                  fontSize="large"
                  data-nosnippet
                  aria-hidden="true"
                />
                <BaseSubheading
                  type="subheading"
                  blendMode="normal"
                  richText={subheading}
                  fontColor={fontColor}
                  fontSize="large"
                  className={`${transitionClasses.color.text}`}
                />
              </>
            )}
          </Content>
        </StyledWrapper>
      </StyledSection>
    );
  }
);

export default PageHero;
