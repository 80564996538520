import { css } from 'styled-components';
import { StyledSVG } from '@components/svg-icons/svg-icon.styled';
import { generateScales } from '@helpers/generate-media-queries';
import getColorMode, { VariantType } from '@helpers/get-color-mode';

export const Button = css<{
  variant?: VariantType['variant'];
  outlined?: boolean;
  size?: string;
  disabled?: boolean;
}>`
  // base button
  ${({ theme }) => css`
    text-decoration: none;
    text-transform: uppercase;
    font-family: ${theme.fontFamily.text};
    font-weight: 500;
    font-size: ${theme.fontSize.body.desktop};
    cursor: pointer;
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    border-width: ${theme.borderWidths.ui};
    border-style: solid;
  `}

  // color variant
  ${({ theme, variant = 'primary', outlined }) => {
    const { backgroundColor, accentColor } = getColorMode(variant);

    const solidStyles = css`
      background: ${theme.colors[accentColor]};
      color: ${theme.colors[backgroundColor]};
      border-color: ${theme.colors[accentColor]};
    `;

    const outlinedStyles = css`
      background-color: transparent;
      color: ${theme.colors[accentColor]};
      border-color: ${theme.colors[accentColor]};
    `;

    return css`
      ${solidStyles}

      :hover {
        ${outlinedStyles}
      }

      // icon
      ${StyledSVG} {
        stroke: ${theme.colors.primary};
      }

      // outlined
      ${outlined &&
      css`
        ${outlinedStyles}

        :hover {
          background: currentColor;

          span {
            filter: invert(100%) grayscale(100%) contrast(100%);
            mix-blend-mode: luminosity;
          }
        }
      `}
    `;
  }}
  
  ${({ theme, disabled }) => css`
    ${disabled &&
    css`
      background-color: transparent;
      color: ${theme.colors.muted};
      border-color: currentColor;
      opacity: 0.5;
      filter: saturate(50%);
      cursor: auto;

      &:hover {
        background-color: transparent;
        color: ${theme.colors.muted};
        border-color: currentColor;
        span {
          filter: unset;
          mix-blend-mode: unset;
        }
      }
    `}
  `}
`;

export const LargeButton = css`
  ${Button}

  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.lg;

    return css`
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize, theme)}
      ${generateScales('padding-left', mobile.borderRadius, desktop.borderRadius, theme)}
      ${generateScales('padding-right', mobile.borderRadius, desktop.borderRadius, theme)}
      ${generateScales('height', mobile.height, desktop.height, theme)}
    `;
  }}
`;

export const MediumButton = css`
  ${Button}

  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.md;

    return css`
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize, theme)}
      ${generateScales('padding-left', mobile.borderRadius, desktop.borderRadius, theme)}
      ${generateScales('padding-right', mobile.borderRadius, desktop.borderRadius, theme)}
      ${generateScales('height', mobile.height, desktop.height, theme)}
    `;
  }}
`;

export const SmallButton = css`
  ${Button}

  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.sm;

    return css`
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize, theme)}
      ${generateScales('padding-left', mobile.borderRadius, desktop.borderRadius, theme)}
      ${generateScales('padding-right', mobile.borderRadius, desktop.borderRadius, theme)}
      ${generateScales('height', mobile.height, desktop.height, theme)}
    `;
  }}
`;
