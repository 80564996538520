import React from 'react';
import { TextComponentProps } from '@components/text/text-props.type';
import { StyledEyebrow } from './eyebrow.styled';

const Eyebrow = ({
  fontColor = 'primaryLight',
  stringText,
  className,
  fontSize = 'eyebrow',
}: TextComponentProps): JSX.Element => (
  <StyledEyebrow fontColor={fontColor} fontSize={fontSize} className={className}>
    {stringText}
  </StyledEyebrow>
);

export default Eyebrow;
