import styled, { css } from 'styled-components';
import Text from '@components/text';
import { defaultTheme } from '@styles/theme.styled';
import getColorMode, { VariantType } from '@helpers/get-color-mode';

export const SliderContainer = styled.div`
  width: 100%;
  padding: 0 0 0 1rem;
`;

export const Container = styled.div<{ $dotsColor?: keyof typeof defaultTheme.colors }>`
  ${({ theme, $dotsColor = 'secondaryLight' }) => css`
    padding: 0 0 5rem 0;

    .slick-slider {
      display: flex;
      align-items: center;
    }

    .slick-dots {
      bottom: -64px;
    }

    .slick-dots li button {
      &::before {
        background-color: transparent;
        color: transparent;
        height: 1rem;
        width: 1rem;
        border-radius: ${theme.borderRadius.large};
        border: 1.5px solid ${theme.colors[$dotsColor]};
        opacity: 1;
      }
    }

    .slick-dots .slick-active button {
      &::before {
        background-color: ${theme.colors[$dotsColor]};
        color: transparent;
        border-radius: ${theme.borderRadius.large};
        opacity: 1;
      }
    }
  `};
`;

export const TextContainer = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    width: 100%;
    max-width: 40.75rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      max-width: 30rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      max-width: 70%;
    }
  `};
`;

export const StyledText = styled(Text)<{ colorMode: VariantType['variant'] }>`
  ${({ theme, colorMode }) => {
    const { fontColor } = getColorMode(colorMode);
    return css`
      margin: 0 0 2rem 0;
      display: inline-block;

      ::before {
        content: '\\201C';
        position: absolute;
        top: 0;
        font-size: 6rem;
        color: ${theme.colors[fontColor]};
        transform: translate(-50px, -30px);
      }
    `;
  }}
`;

export const SlideButton = styled.button<{
  $borderColor: keyof typeof defaultTheme.colors;
  $hoverStroke?: keyof typeof defaultTheme.colors;
  isNext: boolean;
}>`
  ${({ theme, $borderColor, isNext }) => css`
    width: 8rem;
    height: 8rem;
    flex-shrink: 0;
    position: relative;
    ${isNext
      ? css`
          right: 2rem;
          svg {
            transform: rotate(-90deg);
          }
        `
      : css`
          left: 2rem;
          svg {
            transform: rotate(90deg);
          }
        `}
    background: none;
    border: 1.5px solid ${theme.colors[$borderColor]};
    border-radius: 50%;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      svg path {
        stroke: currentColor !important;
        filter: invert(100%) grayscale(100%);
        mix-blend-mode: luminosity;
      }
      background-color: ${theme.colors[$borderColor]};
    }

    @media (max-width: ${theme.breakpoints.xl}) {
      width: 12vw;
      height: 12vw;

      ${isNext
        ? css`
            right: -3vw;
          `
        : css`
            left: -3vw;
          `}
    }
  `}
`;
