import React, { Dispatch } from 'react';

export const validateEmail = (value: string): boolean => {
  const regexp = /.+@[^@]+\.[^@]{1,}$/g;

  return regexp.test(value);
};

export const updateErrors = (
  name: string,
  isValid: boolean,
  errors: string[],
  setErrors: Dispatch<React.SetStateAction<string[]>>
): void => {
  const newErrors = !errors.includes(name) ? [...errors, name] : errors;
  const removeError = errors.filter((error) => error !== name);

  setErrors(isValid ? removeError : newErrors);
};

interface HandleBlurOptions {
  value: string;
  validate?: (value: string) => boolean;
  setIsWarningDisplayed: Dispatch<React.SetStateAction<boolean>>;
}

export const defaultValidate = ({
  value,
  validate,
  setIsWarningDisplayed,
}: HandleBlurOptions): boolean => {
  const isNotEmpty = value !== '';
  const isValid = validate ? validate(value) : isNotEmpty;

  setIsWarningDisplayed(!isValid);
  return isValid;
};
