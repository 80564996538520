import styled, { css } from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import { generateScalingQueries, generateScales } from '@helpers/generate-media-queries';

export const StyledTitle = styled.h1<{
  margin: keyof typeof defaultTheme.spacing;
  fontSize: keyof typeof defaultTheme.fontSize;
  fontColor: keyof typeof defaultTheme.colors;
}>`
  ${({ theme, margin, fontSize, fontColor }) => {
    const bottomMargin: string = theme.spacing[margin];

    return css`
      margin: 0 0 ${theme.spacing[margin]} 0;
      font-family: ${theme.fontFamily.display};
      font-weight: ${theme.fontWeights.normal};
      line-height: ${theme.lineHeights.base};
      color: ${theme.colors[fontColor]};

      ${generateScales(
        'font-size',
        theme.fontSize[fontSize].mobile,
        theme.fontSize[fontSize].desktop,
        theme
      )}

      ${generateScalingQueries('margin-bottom', bottomMargin, theme)}
    `;
  }}
`;
