import { DefaultTheme } from 'styled-components';

export const curvedRange = (min: number, max: number, n: number, curve = 0): Array<number> => {
  const interpolate = (a: number, b: number, t: number) => a * (1 - t) + b * t;
  const pow = interpolate(1, curve < 0 ? 3 : 1 / 3, Math.abs(+curve || 0) / 100);
  const arr = Array(+n);

  for (let i = 0; i < arr.length; i += 1) arr[i] = interpolate(min, max, (i / (n - 1)) ** pow);

  return arr as Array<number>;
};

export const generateScales = (
  property: string,
  mobileVal: string,
  desktopVal: string,
  defaultTheme: DefaultTheme
): string => {
  const { breakpoints } = defaultTheme;
  const breakpointsArr = Object.keys(breakpoints);
  const n = breakpointsArr.length + 1;
  const mobileSize = parseFloat(mobileVal);
  const desktopSize = parseFloat(desktopVal);
  const expVals = curvedRange(mobileSize, desktopSize, n, 5).reverse();
  let responsiveCSS = `
    ${property}: ${desktopVal};
  `;

  breakpointsArr.reverse().forEach((breakpoint, i) => {
    responsiveCSS += `
    @media only screen and (max-width: ${breakpoints[breakpoint as keyof typeof breakpoints]}) {
      ${property}: ${expVals[i + 1]}rem;
    }
    `;
  });

  return responsiveCSS;
};

// generates styles that scales property from xs to xl breakpoints
export const generateScalingQueries = (
  property: string,
  maxValue: string,
  defaultTheme: DefaultTheme
): string => {
  const { breakpoints } = defaultTheme;
  let templateLiteral = ``;

  // sort theme values to sequentially generate markup
  const breakpointsSorted = Object.keys(breakpoints).sort((firstEl, secondEl) => {
    const firstBreakpoint = parseInt(breakpoints[firstEl as keyof typeof breakpoints], 10);
    const secondBreakpoint = parseInt(breakpoints[secondEl as keyof typeof breakpoints], 10);

    return firstBreakpoint - secondBreakpoint;
  });

  // generate media query markup
  for (let i = 0; i < breakpointsSorted.length; i += 1) {
    const maxBreakpoint = breakpointsSorted[i] as keyof typeof breakpoints;
    const scale = breakpointsSorted[i] as keyof typeof breakpoints;

    // smallest breakpoint (<xs)
    if (i === 0) {
      templateLiteral += `
        @media only screen and (min-width: 0px) and (max-width: ${defaultTheme.breakpoints[maxBreakpoint]}) {
          ${property}: calc(${maxValue} * ${defaultTheme.scales[scale]});
        }
      `;
    } else {
      // additional breakpoint ranges (sm to xl)
      const minBreakpoint = breakpointsSorted[i - 1] as keyof typeof defaultTheme.breakpoints;
      const minWidth = parseInt(defaultTheme.breakpoints[minBreakpoint], 10);
      templateLiteral += `
        @media only screen and (min-width: ${minWidth + 1}px) and (max-width: ${
        defaultTheme.breakpoints[maxBreakpoint]
      }) {
          ${property}: calc(${maxValue} * ${defaultTheme.scales[scale]});
        }
      `;
    }
  }

  return templateLiteral;
};
