import { defaultTheme } from '@styles/theme.styled';

export interface VariantType {
  variant: 'primary' | 'primaryLight' | 'secondary' | 'secondaryLight';
}

interface ColorModeType {
  backgroundColor: keyof typeof defaultTheme.colors;
  fontColor: keyof typeof defaultTheme.colors;
  accentColor: keyof typeof defaultTheme.colors;
  blendedColorBottom: keyof typeof defaultTheme.colors;
  blendedColorMiddle: keyof typeof defaultTheme.colors;
  blendedColorTop: keyof typeof defaultTheme.colors;
  blendModeTop: string;
  blendModeMiddle: string;
}

const getColorMode = (variant: VariantType['variant']): ColorModeType => {
  const primaryMode: ColorModeType = {
    backgroundColor: 'primary',
    fontColor: 'white',
    accentColor: 'white',
    blendedColorBottom: 'white',
    blendedColorMiddle: 'black',
    blendModeMiddle: 'color-burn',
    blendedColorTop: 'primary',
    blendModeTop: 'lighten',
  };

  switch (variant) {
    case 'primary':
      return primaryMode;
    case 'primaryLight':
      return {
        backgroundColor: 'primaryLight',
        fontColor: 'primary',
        accentColor: 'primary',
        blendedColorBottom: 'black',
        blendedColorMiddle: 'primaryLight',
        blendModeMiddle: 'color-dodge',
        blendedColorTop: 'primary',
        blendModeTop: 'lighten',
      };
    case 'secondary':
      return {
        backgroundColor: 'secondary',
        fontColor: 'white',
        accentColor: 'secondaryLight',
        blendedColorBottom: 'black',
        blendedColorMiddle: 'white',
        blendedColorTop: 'secondaryLight',
        blendModeMiddle: 'color-burn',
        blendModeTop: 'screen',
      };
    case 'secondaryLight':
      return {
        backgroundColor: 'white',
        fontColor: 'secondary',
        accentColor: 'secondary',
        blendedColorBottom: 'black',
        blendedColorMiddle: 'primaryLight',
        blendModeMiddle: 'color-dodge',
        blendedColorTop: 'secondary',
        blendModeTop: 'lighten',
      };
    default:
      return primaryMode;
  }
};

export default getColorMode;
