import React, { useState, useRef } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { defaultColorMode } from '@constants/default-values';
import ContactModal from '@components/contact-modal';
import getColorMode, { VariantType } from '@helpers/get-color-mode';
import Wrapper from '@components/wrapper.styled';
import { transitionClasses } from '@helpers/animate';
import {
  HeaderWrapper,
  HeaderBackgroundColor,
  StyledHeader,
  Content,
  Menu,
  Logo,
  StyledButton,
  StyledLink,
  MainContainer,
} from './header.styled';
import {
  BurgerButton,
  Line,
  MobileMenu,
  MobileContent,
  StyledMobileButton,
} from './mobile-header.styled';

interface HeaderQueryInterface {
  prismicGeneralConfig: {
    data: {
      logo: {
        alt?: string;
        url: string;
        target: string;
      };
      header_links?: {
        link: {
          url: string;
          uid: string;
          target: string;
        };
        link_label: string;
      }[];
    };
  };
}

export const query = graphql`
  query HeaderQuery {
    prismicGeneralConfig {
      data {
        logo {
          url
          alt
        }
        header_links {
          link {
            uid
            url
            target
          }
          link_label
        }
      }
    }
  }
`;

interface HeaderProps {
  colorMode?: VariantType['variant'];
}

const Header = ({ colorMode = defaultColorMode }: HeaderProps): JSX.Element => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const headerRef = useRef<HTMLHeadingElement>(null);

  const { backgroundColor, fontColor } = getColorMode(colorMode);

  const {
    prismicGeneralConfig: {
      data: { logo, header_links },
    },
  }: HeaderQueryInterface = useStaticQuery(query);

  const headerHeight = headerRef?.current?.offsetHeight;

  const handleClick = () => {
    setIsModalOpen(!isModalOpen);
    setIsMenuVisible(false);
  };

  const getButtonLabel = () => (isModalOpen ? 'Close' : 'Book Now');

  return (
    <>
      <MainContainer>
        <StyledHeader $isActive={isMenuVisible} colorMode={colorMode}>
          <HeaderWrapper>
            <Content>
              {logo.url && (
                <Link to="/" id="logo-navbar-link" aria-label="Navigate to the Homepage">
                  <Logo
                    imageUrl={logo.url}
                    $color={fontColor}
                    className={`${transitionClasses.color.fill}`}
                  />
                </Link>
              )}
              <Menu>
                {header_links?.map(({ link, link_label }) => (
                  <StyledLink
                    href={link.url}
                    target={link.target}
                    $fontColor={fontColor}
                    className={`${transitionClasses.color.text}`}
                    key={`navlink-${link.url}`}
                  >
                    {link_label}
                  </StyledLink>
                ))}
              </Menu>
              <StyledButton
                label={getButtonLabel()}
                size="small"
                variant={colorMode}
                outlined
                onClick={() => setIsModalOpen(!isModalOpen)}
                className={`${transitionClasses.color.stroke} ${transitionClasses.color.text}`}
              />
              <BurgerButton
                onClick={() => setIsMenuVisible(!isMenuVisible)}
                $isActive={isMenuVisible}
              >
                <Line $color={fontColor} className={`${transitionClasses.color.fill}`} />
                <Line $color={fontColor} className={`${transitionClasses.color.fill}`} />
                <Line $color={fontColor} className={`${transitionClasses.color.fill}`} />
              </BurgerButton>
            </Content>
          </HeaderWrapper>
          <HeaderBackgroundColor id="navColor" ref={headerRef} $backgroundColor={backgroundColor} />
        </StyledHeader>
        <MobileContent
          isVisible={isMenuVisible}
          headerHeight={headerHeight}
          colorMode={colorMode}
          className={transitionClasses.color.background}
        >
          <Wrapper>
            <MobileMenu>
              {header_links?.map(({ link, link_label }) => (
                <StyledLink
                  href={link.url}
                  target={link.target}
                  $fontColor={fontColor}
                  className={`${transitionClasses.color.text}`}
                  key={`mobile-navlink-${link.url}`}
                >
                  {link_label}
                </StyledLink>
              ))}
              <StyledMobileButton
                label={getButtonLabel()}
                variant={colorMode}
                size="small"
                outlined
                onClick={handleClick}
                className={`${transitionClasses.color.stroke} ${transitionClasses.color.text}`}
              />
            </MobileMenu>
          </Wrapper>
        </MobileContent>
      </MainContainer>
      <ContactModal isOpen={isModalOpen} colorMode={colorMode} />
    </>
  );
};

export default Header;
