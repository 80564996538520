import React, { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import GlobalStyle from '@components/global-style';
import Header from '@components/header';
// import ContactModal from '@components/contact-modal';
import Footer from '@components/footer';
import { QueryThemeConfigType } from '@layouts/helpers/theme-config.type';
import Cookie from '@components/cookie';
import { VariantType } from '@helpers/get-color-mode';
import getThemeConfig from './helpers/get-theme-config';
import { Container, Content } from './default-layout.styled';

interface LayoutProperties {
  children: ReactNode;
  navbarColorMode?: VariantType['variant'];
  footerColorMode?: VariantType['variant'];
}

interface LayoutQueryType extends QueryThemeConfigType {
  allPrismicPage: {
    nodes: {
      data: {
        body: {
          primary: {
            color_mode?: VariantType['variant'];
          };
        }[];
      };
    }[];
  };
}
const Layout = ({ children, navbarColorMode, footerColorMode }: LayoutProperties): JSX.Element => {
  const {
    prismicThemeConfig: { data },
    // allPrismicPage: { nodes },
  } = useStaticQuery<LayoutQueryType>(graphql`
    query ThemeConfigQuery {
      allPrismicPage(filter: { uid: { eq: "contact" } }) {
        nodes {
          data {
            body {
              ... on PrismicPageDataBodyContactForm {
                primary {
                  color_mode
                }
              }
            }
          }
        }
      }
      prismicThemeConfig {
        ...ThemeConfigFragment
      }
    }
  `);

  const defaultTheme = getThemeConfig(data);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const modalOptions = { isModalOpen, setIsModalOpen };
  // const { color_mode } = nodes[0].data.body[0].primary;

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container>
        <GlobalStyle />
        <Header
          colorMode={navbarColorMode}
          // modalOptions={modalOptions}
        />
        {/* <ContactModal isOpen={isModalOpen} colorMode={color_mode} /> */}
        <Content>{children}</Content>
        <Cookie />
        <Footer colorMode={footerColorMode} />
      </Container>
    </ThemeProvider>
  );
};

export default Layout;
