import styled, { css } from 'styled-components';
import Image from '@components/image';
import LinkComponent from '@components/link';
import Grid from '@components/grid';
import { defaultImageSize } from '@constants/default-values';
import Text from '@components/text';

export const StyledGrid = styled(Grid)`
  ${({ theme, itemsPerRow = 3 }) => css`
    max-width: 1080px;
    margin: 0 auto;
    grid-gap: 4rem;

    ${itemsPerRow === 2 &&
    css`
      grid-gap: 6rem;
      max-width: 54rem;
    `}

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      grid-template-columns: repeat(${itemsPerRow > 3 ? 3 : itemsPerRow}, 1fr);
      grid-gap: 2rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem;
      column-gap: 3rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 4rem;
    }
  `}
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div<{ isShifted: boolean; itemsPerRow?: number }>`
  display: flex;
  flex-direction: column;

  ${({ isShifted, itemsPerRow, theme }) =>
    isShifted &&
    itemsPerRow === 4 &&
    css`
      :nth-child(4n - 7) {
        margin-top: 0rem;
      }

      :nth-child(4n - 6) {
        margin-top: 0rem;
      }

      :nth-child(4n - 5) {
        margin-top: 0rem;
      }

      :nth-child(4n - 4) {
        margin-top: 0rem;
      }

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        :nth-child(n) {
          margin-top: 0rem;
        }
      }
    `};

  ${({ isShifted, itemsPerRow, theme }) =>
    isShifted &&
    itemsPerRow === 3 &&
    css`
      :nth-child(3n - 3) {
        margin-top: 0rem;
      }

      :nth-child(3n - 4) {
        margin-top: 0rem;
      }

      :nth-child(3n - 5) {
        margin-top: 0rem;
      }

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        :nth-child(n) {
          margin-top: 0rem;
        }
      }
    `};

  ${({ isShifted, itemsPerRow, theme }) =>
    isShifted &&
    itemsPerRow === 2 &&
    css`
      :nth-child(2n - 3) {
        margin-top: 0rem;
      }

      :nth-child(2n - 2) {
        margin-top: 0rem;
      }

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        :nth-child(n) {
          margin-top: 0rem;
        }
      }
    `};
`;

export const ItemImage = styled(Image)<{ imageSize: string; layoutType: string }>`
  ${({ imageSize, layoutType, theme }) =>
    imageSize === defaultImageSize
      ? css`
          width: 100%;
          margin: 0 auto 1.5rem;
          display: block;

          @media only screen and (max-width: ${theme.breakpoints.md}) {
            max-height: 12rem;
          }

          @media only screen and (max-width: ${theme.breakpoints.sm}) {
            max-height: none;
            margin: 0 0 1.5rem;
          }
        `
      : css`
          margin: 0 auto 2rem;
          display: block;
          width: 8rem;
          min-height: 8rem;
          align-self: ${layoutType};

          @media only screen and (max-width: ${theme.breakpoints.sm}) {
            width: 6rem;
            min-height: 6rem;
            margin: 0 0 1.5rem;
          }
        `};
`;

export const ItemText = styled.div<{ itemsLayoutType: string }>`
  text-align: ${({ itemsLayoutType }) => itemsLayoutType};
`;

export const ItemLink = styled(LinkComponent)`
  margin-top: 0.5rem;
`;

export const StyledText = styled(Text)``;
