import styled, { css } from 'styled-components';
import Text from '@components/text';

export const Container = styled.div<{ align?: string }>`
  ${({ theme, align }) => css`
    width: 100%;
    display: flex;
    flex-direction: ${align === 'left' ? 'row' : 'row-reverse'};
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 4rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      gap: 1.5rem;
    }
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      align-items: center;
      gap: 2rem;
    }
  `}
`;

export const TextContainer = styled.div`
  ${({ theme }) => css`
    max-width: 48rem;
    text-align: center;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      width: 100%;
      max-width: 45%;
    }
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      max-width: 100%;
    }
  `}
`;

export const Title = styled(Text)<{ align?: string }>`
  ${({ theme, align }) => css`
    text-align: ${align};
    text-align: center;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      text-align: center;
    }
  `}
`;

export const Description = styled(Text)<{ align?: string }>`
  ${({ theme, align }) => css`
    text-align: ${align};
    text-align: center;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      text-align: center;
    }
  `}
`;

export const ImageContainer = styled.div`
  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      width: 100%;
      max-width: 55%;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      max-width: 100%;
    }
  `}
`;
