import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import Text from '@components/text';
import { defaultTheme } from '@styles/theme.styled';
import Wrapper from '@components/wrapper.styled';
import { transitionClasses } from '@helpers/animate';
import { Container, StyledSubheading, StyledTitle } from './heading-block.styled';

export interface HeadingBlockProps {
  eyebrow?: string;
  title?: string;
  subheading?: RichTextBlock[];
  fontColor?: keyof typeof defaultTheme.colors;
}

const HeadingBlock = ({
  eyebrow,
  title,
  subheading,
  fontColor,
}: HeadingBlockProps): JSX.Element | null => {
  if (!eyebrow && !title && !subheading?.length) return null;

  return (
    <Wrapper>
      <Container>
        {eyebrow && (
          <Text
            type="eyebrow"
            stringText={eyebrow}
            fontColor={fontColor}
            className={transitionClasses.color.text}
          />
        )}
        {title && (
          <StyledTitle
            type="title"
            stringText={title}
            fontColor={fontColor}
            className={transitionClasses.color.accentText}
          />
        )}
        {!!subheading?.length && (
          <StyledSubheading
            type="subheading"
            richText={subheading}
            fontColor={fontColor}
            className={transitionClasses.color.text}
          />
        )}
      </Container>
    </Wrapper>
  );
};

export default HeadingBlock;
