import React, { forwardRef } from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import { defaultColorMode } from '@constants/default-values';
import getColorMode, { VariantType } from '@helpers/get-color-mode';
import { ImageProps } from '@components/image';
import { transitionClasses } from '@helpers/animate';
import {
  Content,
  ImageContent,
  StyledFirstImage,
  StyledSecondImage,
  StyledThirdImage,
  BaseTitle,
  SpacerTitle,
  SpacerSubheading,
  BaseSubheading,
  StyledSection,
  StyledWrapper,
} from './two-images-page-hero.styled';

export interface TwoImagesPageHeroProps {
  title?: string;
  colorMode?: VariantType['variant'];
  subheading?: RichTextBlock[];
  firstImage?: ImageProps;
  secondImage?: ImageProps;
  thirdImage?: ImageProps;
}

const TwoImagesPageHero = forwardRef<HTMLElement, TwoImagesPageHeroProps>(
  (
    {
      title,
      subheading = [],
      firstImage,
      secondImage,
      thirdImage,
      colorMode = defaultColorMode,
    }: TwoImagesPageHeroProps,
    ref
  ): JSX.Element | null => {
    if (!title && !subheading) {
      return null;
    }

    const { backgroundColor, fontColor } = getColorMode(colorMode);

    return (
      <StyledSection backgroundColor={backgroundColor} ref={ref}>
        <StyledWrapper>
          <Content>
            {title && (
              <>
                <SpacerTitle
                  type="title"
                  tag="h1"
                  stringText={title}
                  fontColor={fontColor}
                  fontSize="heading2"
                  data-nosnippet
                  aria-hidden="true"
                />
                <BaseTitle
                  type="title"
                  tag="h1"
                  stringText={title}
                  fontColor={fontColor}
                  fontSize="heading2"
                  className={`${transitionClasses.color.text}`}
                />
              </>
            )}
            <ImageContent id="image-content">
              <StyledFirstImage
                url={firstImage?.url}
                alt={firstImage?.alt}
                className={transitionClasses.parallax}
                data={{ 'data-parallax-offset': 1.5, 'data-parallax-trigger-id': 'image-content' }}
              />
              <StyledSecondImage
                url={secondImage?.url}
                alt={secondImage?.alt}
                className={transitionClasses.parallax}
                data={{ 'data-parallax-trigger-id': 'image-content' }}
              />
              <StyledThirdImage
                url={thirdImage?.url}
                alt={thirdImage?.alt}
                data={{ 'data-parallax-offset': 1.5, 'data-parallax-trigger-id': 'image-content' }}
                className={transitionClasses.parallax}
              />
            </ImageContent>
            {subheading.length > 0 && (
              <>
                <SpacerSubheading
                  type="subheading"
                  blendMode="normal"
                  richText={subheading}
                  fontColor={fontColor}
                  fontSize="large"
                  data-nosnippet
                  aria-hidden="true"
                />
                <BaseSubheading
                  type="subheading"
                  blendMode="normal"
                  richText={subheading}
                  fontColor={fontColor}
                  fontSize="large"
                  className={`${transitionClasses.color.text}`}
                />
              </>
            )}
          </Content>
        </StyledWrapper>
      </StyledSection>
    );
  }
);

export default TwoImagesPageHero;
