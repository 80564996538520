export interface LinkType {
  url?: string;
  uid?: string;
  target?: string;
}

interface LinkConfigType {
  url: string;
  isInternal: boolean;
  target: string;
}

const getLinkConfig = (link: LinkType): LinkConfigType => ({
  url: link?.uid ? `/${link?.uid}` : link?.url || '',
  isInternal: !!link?.uid,
  target: link?.target || '_self',
});

export default getLinkConfig;
