import styled, { css } from 'styled-components';
import { generateScales } from '@helpers/generate-media-queries';
import { underlineTransition } from '@components/link/link.styled';
import getColorMode, { VariantType } from '@helpers/get-color-mode';

export const RichTextContainer = styled.div<{ colorMode: VariantType['variant'] }>`
  ${({ theme, colorMode }) => {
    const { backgroundColor, fontColor } = getColorMode(colorMode);
    return css`
      margin: ${theme.padding.md} 0;
      font-family: ${theme.fontFamily.text};
      font-size: ${theme.fontSize.body.desktop};
      color: ${theme.colors[fontColor]};
      line-height: 1.6875rem;

      p + p {
        margin-top: 1.5rem;
      }

      em {
        font-style: italic;
      }

      strong {
        font-weight: bold;
      }

      a,
      a:visited {
        color: ${theme.colors[backgroundColor]};
        ${underlineTransition}
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: ${theme.fontFamily.display};
        color: ${theme.colors[fontColor]};
        margin: 3rem 0 1.5rem;
        line-height: ${theme.lineHeights.sm};
        font-weight: bold;
      }

      h1 {
        ${generateScales(
          'font-size',
          theme.fontSize.heading1.mobile,
          theme.fontSize.heading1.desktop,
          theme
        )}
      }

      h2 {
        ${generateScales(
          'font-size',
          theme.fontSize.heading2.mobile,
          theme.fontSize.heading2.desktop,
          theme
        )}
      }

      h3 {
        ${generateScales(
          'font-size',
          theme.fontSize.heading3.mobile,
          theme.fontSize.heading3.desktop,
          theme
        )}
      }

      h4 {
        ${generateScales(
          'font-size',
          theme.fontSize.heading4.mobile,
          theme.fontSize.heading4.desktop,
          theme
        )}
      }

      h5 {
        ${generateScales(
          'font-size',
          theme.fontSize.heading5.mobile,
          theme.fontSize.heading5.desktop,
          theme
        )}
      }

      h6 {
        ${generateScales(
          'font-size',
          theme.fontSize.heading6.mobile,
          theme.fontSize.heading6.desktop,
          theme
        )}
      }

      ul,
      ol {
        margin: 2rem 0;
        padding-left: 1.5rem;
      }
      ul {
        li {
          margin-top: 1rem;
          list-style-type: disc;
        }
      }

      ol {
        li {
          margin-top: 1rem;
          list-style-type: decimal;
        }
      }
    `;
  }}
`;
