import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { defaultTheme } from '@styles/theme.styled';
import getColorMode, { VariantType } from '@helpers/get-color-mode';
import React from 'react';

gsap.registerPlugin(ScrollTrigger);

// Transition Classes
export const transitionClasses = {
  color: {
    accentText: 'transition-color-accent-text',
    background: 'transition-color-background',
    fill: 'transition-color-fill',
    accentBorder: 'transition-color-accent-border',
    border: 'transition-color-border',
    accentStroke: 'transition-color-accent-stroke',
    stroke: 'transition-color-stroke',
    text: 'transition-color-text',
  },
  blendMode: {
    bottom: 'transition-blended-text-bottom',
    middle: 'transition-blend-mode-middle',
    top: 'transition-blend-mode-top',
  },
  blended: {
    border: {
      bottom: 'transition-blended-text-bottom',
      middle: 'transition-blended-border-middle',
      top: 'transition-blended-border-top',
    },
    fill: {
      bottom: 'transition-blended-text-bottom',
      middle: 'transition-blended-fill-middle',
      top: 'transition-blended-fill-top',
    },
    stroke: {
      bottom: 'transition-blended-text-bottom',
      middle: 'transition-blended-stroke-middle',
      top: 'transition-blended-stroke-top',
    },
    text: {
      bottom: 'transition-blended-text-bottom',
      middle: 'transition-blended-text-middle',
      top: 'transition-blended-text-top',
    },
  },
  parallax: 'transition-parallax',
};

// Animation Defaults
const tweenDefaults = { duration: 0.375, ease: 'power1.inOut' };
export const startVerticalPosition = 75;
const defaultParallaxOffset = 1.125;

/**
 * hexToRGB - converts hex color to a RGB/RGBA color
 * @param {string} hex The "#RRGGBB" hex color string
 * @param {string} alpha Optional alpha value
 * @returns {string} RGB or RGBA string value
 */
const hexToRGB = (hex: string, alpha: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return `rgb(${r}, ${g}, ${b})`;
};

export const parallax = (className = transitionClasses.parallax): ScrollTrigger[] => {
  const animationInstances: ScrollTrigger[] = [];
  const triggerElements = document.getElementsByClassName(className);

  for (let i = 0; i < triggerElements.length; i += 1) {
    const triggerElement: Element | null = triggerElements.item(i);

    if (triggerElement !== null) {
      const triggerOffset = triggerElement.getAttribute('data-parallax-offset');
      const triggerCustomId = triggerElement.getAttribute('data-parallax-trigger-id');
      const availableHeight =
        window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      const baseOffset =
        triggerOffset !== null
          ? parseFloat(parseFloat(triggerOffset).toFixed(4))
          : defaultParallaxOffset;
      const animationOffset = availableHeight * baseOffset - availableHeight;

      const parallaxAnimation = (el: Element) =>
        gsap.fromTo(el, { y: animationOffset }, { y: -(animationOffset / 2) });

      animationInstances.push(
        ScrollTrigger.create({
          trigger: triggerCustomId ? `#${triggerCustomId}` : triggerElement,
          start: 'top bottom',
          end: 'bottom top',
          scrub: true,
          animation: parallaxAnimation(triggerElement),
        })
      );
    }
  }

  return animationInstances;
};

/**
 * sectionColorTransition - transitions the background color when scrolling through sections
 * @param {React.RefObject<HTMLElement>} ref React ref
 * @param {VariantType['variant']} nextColorMode Color mode for entering section
 * @param {VariantType['variant']} previousColorMode Color mode for exiting section
 * @returns {ScrollTrigger} Scrolltrigger instance of transition animation
 */
export const sectionColorTransition = (
  ref: React.RefObject<HTMLElement>,
  nextColorMode: VariantType['variant'],
  previousColorMode: VariantType['variant']
): ScrollTrigger => {
  const nextProps = getColorMode(nextColorMode);
  const nextBGColor = defaultTheme.colors[nextProps.backgroundColor];
  const previousProps = getColorMode(previousColorMode);
  const previousBGColor = defaultTheme.colors[previousProps.backgroundColor];

  const colorTimeline = gsap.timeline();

  // background color transitions
  const bodyTween = gsap.fromTo(
    'body',
    { backgroundColor: previousBGColor },
    {
      backgroundColor: nextBGColor,
      ...tweenDefaults,
    }
  );

  // match modal background color to body
  const modalTween = gsap.set('.contact-modal', { backgroundColor: nextBGColor });

  // navigation background color
  const navTween = gsap.fromTo(
    '#navColor',
    {
      background: `linear-gradient(180deg,
        ${hexToRGB(previousBGColor, '0.9')} 12.5%,
        ${hexToRGB(previousBGColor, '0.75')} 37.5%,
        ${hexToRGB(previousBGColor, '0')} 100%)`,
    },
    {
      background: `linear-gradient(180deg,
        ${hexToRGB(nextBGColor, '0.9')} 12.5%,
        ${hexToRGB(nextBGColor, '0.75')} 37.5%,
        ${hexToRGB(nextBGColor, '0')} 100%)`,
      ...tweenDefaults,
    }
  );

  colorTimeline.add(modalTween, 0).add(bodyTween, 0).add(navTween, 0);

  const scrollTriggerInstance = ScrollTrigger.create({
    trigger: ref.current,
    start: `top ${startVerticalPosition}%`,
    preventOverlaps: true,
    fastScrollEnd: true,
    toggleActions: 'play none none reverse',
    animation: colorTimeline,
    // onRefresh() {
    //   // eslint-disable-next-line no-alert
    //   alert('debug');
    // },
  });

  return scrollTriggerInstance;
};

/**
 * elementColorTransitions - transitions the background color when scrolling through sections
 * @param {React.RefObject<HTMLElement>} ref React ref
 * @param {VariantType['variant']} nextColorMode Color mode for entering section
 * @param {VariantType['variant']} previousColorMode Color mode for exiting section
 * @returns {ScrollTrigger} Scrolltrigger instance of transition animation
 */
export const elementColorTransitions = (
  ref: React.RefObject<HTMLElement>,
  nextColorMode: VariantType['variant'],
  previousColorMode: VariantType['variant']
): ScrollTrigger => {
  const nextProps = getColorMode(nextColorMode);
  const nextFontColor = defaultTheme.colors[nextProps.fontColor];
  const nextAccentColor = defaultTheme.colors[nextProps.accentColor];
  const previousProps = getColorMode(previousColorMode);
  const previousFontColor = defaultTheme.colors[previousProps.fontColor];
  const previousAccentColor = defaultTheme.colors[previousProps.accentColor];

  // Regular Text
  const textColorTween = gsap.fromTo(
    `.${transitionClasses.color.text}`,
    {
      color: previousFontColor,
    },
    {
      color: nextFontColor,
      ...tweenDefaults,
    }
  );

  // Accent Text
  const accentTextColorTween = gsap.fromTo(
    `.${transitionClasses.color.accentText}`,
    {
      color: previousAccentColor,
    },
    {
      color: nextAccentColor,
      ...tweenDefaults,
    }
  );

  // Fill color transitions
  const fillTween = gsap.fromTo(
    `.${transitionClasses.color.fill}`,
    { backgroundColor: defaultTheme.colors[previousProps.fontColor] },
    {
      backgroundColor: defaultTheme.colors[nextProps.fontColor],
      ...tweenDefaults,
    }
  );

  // Background color transitions
  const backgroundTween = gsap.fromTo(
    `.${transitionClasses.color.background}`,
    { backgroundColor: defaultTheme.colors[previousProps.backgroundColor] },
    {
      backgroundColor: defaultTheme.colors[nextProps.backgroundColor],
      ...tweenDefaults,
    }
  );

  // Stroked SVG Elements
  const strokeTween = gsap.fromTo(
    `.${transitionClasses.color.stroke}`,
    {
      stroke: previousFontColor,
    },
    {
      stroke: nextFontColor,
      ...tweenDefaults,
    }
  );

  // Accent Stroked SVG Elements
  const accentStrokeTween = gsap.fromTo(
    `.${transitionClasses.color.stroke}`,
    {
      stroke: previousFontColor,
    },
    {
      stroke: nextFontColor,
      ...tweenDefaults,
    }
  );

  // Bordered HTML Elements
  const borderColorTween = gsap.fromTo(
    `.${transitionClasses.color.border}`,
    {
      borderColor: previousFontColor,
    },
    {
      borderColor: nextFontColor,
      ...tweenDefaults,
    }
  );

  // Bordered HTML Elements
  const accentBorderColorTween = gsap.fromTo(
    `.${transitionClasses.color.accentBorder}`,
    {
      borderColor: previousAccentColor,
    },
    {
      borderColor: nextAccentColor,
      ...tweenDefaults,
    }
  );

  // Create timeline with tweems
  const colorTimeline = gsap.timeline();

  // Add specific tweens if elements are present

  // Blended Border
  const hasBlendedBorders: boolean =
    document.getElementsByClassName(transitionClasses.blended.border.middle).length > 0;
  if (hasBlendedBorders) {
    colorTimeline
      .add(
        gsap.fromTo(
          `.${transitionClasses.blended.border.bottom}`,
          {
            borderColor: defaultTheme.colors[previousProps.blendedColorBottom],
          },
          {
            borderColor: defaultTheme.colors[nextProps.blendedColorBottom],
            ...tweenDefaults,
          }
        ),
        0
      )
      .add(
        gsap.fromTo(
          `.${transitionClasses.blended.border.middle}`,
          {
            borderColor: defaultTheme.colors[previousProps.blendedColorMiddle],
          },
          {
            borderColor: defaultTheme.colors[nextProps.blendedColorMiddle],
            ...tweenDefaults,
          }
        ),
        0
      )
      .add(
        gsap.fromTo(
          `.${transitionClasses.blended.border.top}`,
          {
            borderColor: defaultTheme.colors[previousProps.blendedColorTop],
          },
          {
            borderColor: defaultTheme.colors[nextProps.blendedColorTop],
            ...tweenDefaults,
          }
        ),
        0
      );
  }

  // Blended Stroke
  const hasBlendedStrokes: boolean =
    document.getElementsByClassName(transitionClasses.blended.stroke.middle).length > 0;
  if (hasBlendedStrokes) {
    colorTimeline
      .add(
        gsap.fromTo(
          `.${transitionClasses.blended.stroke.bottom}`,
          {
            stroke: defaultTheme.colors[previousProps.blendedColorBottom],
          },
          {
            stroke: defaultTheme.colors[nextProps.blendedColorBottom],
            ...tweenDefaults,
          }
        ),
        0
      )
      .add(
        gsap.fromTo(
          `.${transitionClasses.blended.stroke.middle}`,
          {
            stroke: defaultTheme.colors[previousProps.blendedColorMiddle],
          },
          {
            stroke: defaultTheme.colors[nextProps.blendedColorMiddle],
            ...tweenDefaults,
          }
        ),
        0
      )
      .add(
        gsap.fromTo(
          `.${transitionClasses.blended.stroke.top}`,
          {
            stroke: defaultTheme.colors[previousProps.blendedColorTop],
          },
          {
            stroke: defaultTheme.colors[nextProps.blendedColorTop],
            ...tweenDefaults,
          }
        ),
        0
      );
  }

  // Blended Text
  const hasBlendedText: boolean =
    document.getElementsByClassName(transitionClasses.blended.text.middle).length > 0;
  if (hasBlendedText) {
    colorTimeline
      .add(
        gsap.fromTo(
          `.${transitionClasses.blended.text.bottom}`,
          {
            color: defaultTheme.colors[previousProps.blendedColorBottom],
          },
          {
            color: defaultTheme.colors[nextProps.blendedColorBottom],
            ...tweenDefaults,
          }
        ),
        0
      )
      .add(
        gsap.fromTo(
          `.${transitionClasses.blended.text.middle}`,
          {
            color: defaultTheme.colors[previousProps.blendedColorMiddle],
          },
          {
            color: defaultTheme.colors[nextProps.blendedColorMiddle],
            ...tweenDefaults,
          }
        ),
        0
      )
      .add(
        gsap.fromTo(
          `.${transitionClasses.blended.text.top}`,
          {
            color: defaultTheme.colors[previousProps.blendedColorTop],
          },
          {
            color: defaultTheme.colors[nextProps.blendedColorTop],
            ...tweenDefaults,
          }
        ),
        0
      );
  }

  // Blended Modes
  const hasBlendedModes: boolean =
    document.getElementsByClassName(transitionClasses.blendMode.middle).length > 0;
  if (hasBlendedModes) {
    colorTimeline
      .add(
        gsap.fromTo(
          `.${transitionClasses.blendMode.middle}`,
          {
            mixBlendMode: previousProps.blendModeMiddle,
          },
          {
            mixBlendMode: nextProps.blendModeMiddle,
            ...tweenDefaults,
          }
        ),
        0
      )
      .add(
        gsap.fromTo(
          `.${transitionClasses.blendMode.top}`,
          {
            mixBlendMode: previousProps.blendModeTop,
          },
          {
            mixBlendMode: nextProps.blendModeTop,
            ...tweenDefaults,
          }
        ),
        0
      );
  }

  const hasText: boolean = document.getElementsByClassName(transitionClasses.color.text).length > 0;
  if (hasText) {
    colorTimeline.add(textColorTween, 0);
  }

  const hasAccentText: boolean =
    document.getElementsByClassName(transitionClasses.color.accentText).length > 0;
  if (hasAccentText) {
    colorTimeline.add(accentTextColorTween, 0);
  }

  const hasFills: boolean =
    document.getElementsByClassName(transitionClasses.color.fill).length > 0;
  if (hasFills) {
    colorTimeline.add(fillTween, 0);
  }

  const hasBackgrounds: boolean =
    document.getElementsByClassName(transitionClasses.color.background).length > 0;
  if (hasBackgrounds) {
    colorTimeline.add(backgroundTween, 0);
  }

  const hasStrokes: boolean =
    document.getElementsByClassName(transitionClasses.color.stroke).length > 0;
  if (hasStrokes) {
    colorTimeline.add(strokeTween, 0);
  }

  const hasAccentStrokes: boolean =
    document.getElementsByClassName(transitionClasses.color.accentStroke).length > 0;
  if (hasAccentStrokes) {
    colorTimeline.add(accentStrokeTween, 0);
  }

  const hasBorders: boolean =
    document.getElementsByClassName(transitionClasses.color.border).length > 0;
  if (hasBorders) {
    colorTimeline.add(borderColorTween, 0);
  }

  const hasAccentBorders: boolean =
    document.getElementsByClassName(transitionClasses.color.accentBorder).length > 0;
  if (hasAccentBorders) {
    colorTimeline.add(accentBorderColorTween, 0);
  }

  const scrollTriggerInstance = ScrollTrigger.create({
    trigger: ref.current,
    start: `top ${startVerticalPosition}%`,
    preventOverlaps: true,
    fastScrollEnd: true,
    toggleActions: 'play none none reverse',
    animation: colorTimeline,
  });

  return scrollTriggerInstance;
};

/**
 * swipeInTransition - transitions the background color when scrolling through sections
 * @param {React.RefObject<HTMLElement>} ref React ref
 * @param {boolean} contentTransition Whether to appy the transition to the content
 * @returns {ScrollTrigger} Scrolltrigger instance of transition animation
 */
export const swipeInTransition = (
  ref: React.RefObject<HTMLElement>,
  contentTransition = true
): ScrollTrigger => {
  const colorTimeline = gsap.timeline();
  const sectionContent = ref.current?.firstChild || ref.current;

  colorTimeline
    .set(ref.current, {
      perspective: 1800,
      perspectiveOrigin: 'center center',
    })
    .set(sectionContent, { transformOrigin: '100% 100% 100%' });

  if (contentTransition === true) {
    colorTimeline.add(
      gsap.fromTo(
        sectionContent,
        { translateY: -128, rotateX: '48deg', translateZ: 0, opacity: 0 },
        {
          translateY: 0,
          rotateX: '0deg',
          translateZ: 0,
          opacity: 1,
          ...tweenDefaults,
        }
      ),
      0
    );
  } else {
    // colorTimeline.add(
    //   gsap.fromTo(
    //     sectionContent,
    //     { scale: 1.125, rotateX: '1deg', translateZ: 0, opacity: 0, filter: 'blur(4rem)' },
    //     {
    //       scale: 1.0,
    //       rotateX: '0deg',
    //       translateZ: 0,
    //       opacity: 1,
    //       filter: 'blur(0)',
    //       ...tweenDefaults,
    //     }
    //   ),
    //   0
    // );
  }

  const scrollTriggerInstance = ScrollTrigger.create({
    trigger: ref.current,
    start: `top ${startVerticalPosition}%`,
    toggleActions: 'play none none reverse',
    animation: colorTimeline,
  });

  return scrollTriggerInstance;
};

export interface ModuleProps {
  ref: React.RefObject<HTMLElement>;
  nextColorMode: VariantType['variant'];
  previousColorMode: VariantType['variant'];
}

export const buildTransitions = (modules: ModuleProps[]): ScrollTrigger[] => {
  const animationInstances: ScrollTrigger[] = [];

  modules.forEach((module, index) => {
    const { ref, nextColorMode, previousColorMode } = module;
    if (ref.current) ref.current.style.backgroundColor = 'transparent';
    const displayContentTransition = index > 0;
    const animation = sectionColorTransition(ref, nextColorMode, previousColorMode);
    const elementAnimations = elementColorTransitions(ref, nextColorMode, previousColorMode);
    const swipeAnimations = swipeInTransition(ref, displayContentTransition);
    animationInstances.push(animation, elementAnimations, swipeAnimations);
  });

  return animationInstances;
};
