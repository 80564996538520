import React, { forwardRef } from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import { defaultColorMode } from '@constants/default-values';
import getColorMode, { VariantType } from '@helpers/get-color-mode';
import { transitionClasses } from '@helpers/animate';
import { ImageProps } from '@components/image';
import {
  StyledSection,
  Content,
  ImageContent,
  ImageTopContent,
  StyledFirstImage,
  StyledSecondImage,
  StyledThirdImage,
  BaseTitle,
  StyledWrapper,
  BaseSubheading,
} from './complex-page-hero.styled';

export interface ComplexPageHeroProps {
  title?: string;
  colorMode?: VariantType['variant'];
  subheading?: RichTextBlock[];
  firstImage?: ImageProps;
  secondImage?: ImageProps;
  thirdImage?: ImageProps;
}

const ComplexPageHero = forwardRef<HTMLElement, ComplexPageHeroProps>(
  (
    {
      title,
      subheading = [],
      firstImage,
      secondImage,
      thirdImage,
      colorMode = defaultColorMode,
    }: ComplexPageHeroProps,
    ref
  ): JSX.Element | null => {
    if (!title && !subheading) {
      return null;
    }
    const { backgroundColor, fontColor } = getColorMode(colorMode);
    return (
      <StyledSection backgroundColor={backgroundColor} ref={ref}>
        <StyledWrapper>
          <Content>
            <ImageTopContent id="parallax-top-image">
              <StyledFirstImage
                url={firstImage?.url}
                alt={firstImage?.alt}
                objectFit="cover"
                className={transitionClasses.parallax}
                data={{
                  'data-parallax-offset': 1.25,
                  'data-parallax-trigger-id': 'parallax-top-image',
                }}
              />
              <StyledSecondImage
                url={secondImage?.url}
                alt={secondImage?.alt}
                objectFit="cover"
                className={transitionClasses.parallax}
                data={{
                  'data-parallax-offset': 1.25,
                  'data-parallax-trigger-id': 'parallax-top-image',
                }}
              />
            </ImageTopContent>
            {title && (
              <BaseTitle
                type="title"
                tag="h1"
                stringText={title}
                fontColor={fontColor}
                fontSize="heading1"
                className={`${transitionClasses.color.text}`}
              />
            )}
            <ImageContent id="parallax-image">
              <StyledThirdImage
                url={thirdImage?.url}
                alt={thirdImage?.alt}
                objectFit="cover"
                className={transitionClasses.parallax}
                data={{
                  'data-parallax-offset': 1.375,
                  'data-parallax-trigger-id': 'parallax-image',
                }}
              />
            </ImageContent>
            {subheading.length > 0 && (
              <BaseSubheading
                type="subheading"
                blendMode="normal"
                richText={subheading}
                fontColor={fontColor}
                className={`${transitionClasses.color.text}`}
                fontSize="subheading"
              />
            )}
          </Content>
        </StyledWrapper>
      </StyledSection>
    );
  }
);

export default ComplexPageHero;
