import styled, { css } from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';

export const StyledSVG = styled.svg<{
  $stroke?: keyof typeof defaultTheme.colors;
  $fill?: keyof typeof defaultTheme.colors;
}>`
  ${({ theme, $stroke, $fill }) => css`
    stroke-width: ${theme.borderWidths.icons};
    stroke: ${$stroke ? theme.colors[$stroke] : 'none'};
    fill: ${$fill ? theme.colors[$fill] : 'none'};
  `}
`;

export const StyledPath = styled.path``;
