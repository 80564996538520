import getColorMode, { VariantType } from '@helpers/get-color-mode';
import styled, { css } from 'styled-components';

const StyledInput = styled.input<{ colorMode: VariantType['variant'] }>`
  ${({ theme, colorMode }) => {
    const { fontColor, accentColor } = getColorMode(colorMode);

    return css`
      border: none;
      border-bottom: 1px solid ${theme.colors[fontColor]};
      background-color: transparent;
      color: ${theme.colors[accentColor]};
      outline: none;
      font-family: ${theme.fontFamily.text};
      transition: all ${theme.transitions.normal};

      ::placeholder {
        color: currentColor;
        filter: saturate(50%);
        opacity: 0.65;
      }

      :active {
        border: none;
        border-bottom: 1px solid ${theme.colors[fontColor]};
        background-color: transparent;
      }
    `;
  }}
`;

export default StyledInput;
