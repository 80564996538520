import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { defaultColorMode } from '@constants/default-values';
import generateId from '@helpers/generate-id';
import getColorMode, { VariantType } from '@helpers/get-color-mode';
import Wrapper from '@components/wrapper.styled';
import Image, { ImageProps } from '@components/image';
import SignUpForm from '@components/sign-up-form';
import LinkComponent from '@components/link';
import { PrimaryProps } from '../module/index';
import {
  StyledFooter,
  SocialWrapper,
  TopContainer,
  LogoContainer,
  Logo,
  LinksContainer,
  FormContainer,
  FormWrapper,
  EmailTitle,
  EmailDescription,
  BottomContainer,
  StyledSpan,
  BottomLinksContainer,
  SocialMedia,
  TextButton,
} from './footer.styled';

const metaLinkIdGenerator = generateId();
const socialLinkIdGenerator = generateId();
const formIdGenerator = generateId();

interface LinksType {
  link_label: string;
  link: {
    url: string;
    uid: string;
    target: string;
  };
}

interface SocialMediaType {
  image: ImageProps;
  link: {
    uid: string;
    url: string;
    target: string;
  };
}

interface FooterPageInterface {
  prismicGeneralConfig: {
    data: {
      legal_brand_name?: string;
      logo?: {
        alt: string;
        url: string;
        uid: string;
      };
      footer_links: LinksType[];
      social_media: SocialMediaType[];
      body: {
        slice_type: string;
        primary: PrimaryProps;
      }[];
    };
  };
}

const query = graphql`
  query FooterQuery {
    prismicGeneralConfig {
      data {
        legal_brand_name
        logo {
          alt
          url
        }
        footer_links {
          link {
            uid
            url
            target
          }
          link_label
        }
        social_media {
          image {
            url
            alt
          }
          link {
            url
            target
          }
        }
        body {
          ... on PrismicGeneralConfigDataBodyEmailForm {
            slice_type
            primary {
              title {
                text
              }
              description {
                richText
              }
              input_placeholder
              button_label
            }
          }
        }
      }
    }
  }
`;

interface FooterProps {
  colorMode?: VariantType['variant'];
}

const Footer = ({ colorMode = defaultColorMode }: FooterProps): JSX.Element => {
  const {
    prismicGeneralConfig: {
      data: { legal_brand_name = 'House Caviar', logo, footer_links, social_media, body },
    },
  }: FooterPageInterface = useStaticQuery(query);
  const currentYear = new Date().getFullYear();
  const copyText = `© ${currentYear} ${legal_brand_name}`;

  const { backgroundColor, fontColor, accentColor } = getColorMode(colorMode);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <StyledFooter $backgroundColor={backgroundColor}>
      <Wrapper>
        {logo?.url && (
          <TopContainer $color={fontColor}>
            <LogoContainer $color={fontColor}>
              {logo?.url && <Logo imageUrl={logo.url} $color={fontColor} />}
              <LinksContainer>
                {footer_links.map(({ link_label, link }, i) => {
                  const label = i === footer_links.length - 1 ? link_label : `${link_label}`;

                  return (
                    <React.Fragment key={metaLinkIdGenerator.next().value}>
                      <LinkComponent
                        label={`${label.toUpperCase()}${i !== footer_links.length - 1 ? ',' : ''}`}
                        variant={accentColor}
                        link={link}
                      />
                    </React.Fragment>
                  );
                })}
              </LinksContainer>
            </LogoContainer>
            <FormContainer>
              <FormWrapper>
                {body.map(({ primary }) => (
                  <React.Fragment key={formIdGenerator.next().value}>
                    <EmailTitle
                      type="title"
                      stringText={primary.title?.text}
                      fontColor={fontColor}
                      fontSize="heading6"
                    />
                    <EmailDescription
                      richText={primary.description?.richText}
                      fontColor={accentColor}
                      fontSize="body"
                    />
                    <SignUpForm
                      inputPlaceholder={primary.input_placeholder}
                      buttonLabel={primary.button_label}
                      colorMode={colorMode}
                    />
                  </React.Fragment>
                ))}
              </FormWrapper>
            </FormContainer>
          </TopContainer>
        )}
      </Wrapper>
      <Wrapper>
        <BottomContainer>
          <StyledSpan $color={fontColor}>{copyText}</StyledSpan>
          <TextButton type="button" onClick={handleClick} $color={accentColor}>
            (BACK TO THE TOP)
          </TextButton>
          <BottomLinksContainer>
            <SocialWrapper>
              {social_media.map(({ image, link }) => {
                if (!image?.url || !link?.url) {
                  return null;
                }

                return (
                  <SocialMedia
                    href={link?.url}
                    target={link?.target}
                    rel="noreferrer"
                    key={socialLinkIdGenerator.next().value}
                    title={
                      image?.alt !== undefined
                        ? `Follow us on ${image?.alt}`
                        : 'Follow us on social'
                    }
                    $backgroundColor={fontColor}
                  >
                    <Image url={image?.url} alt={image?.alt} />
                  </SocialMedia>
                );
              })}
            </SocialWrapper>
          </BottomLinksContainer>
        </BottomContainer>
      </Wrapper>
    </StyledFooter>
  );
};

export default Footer;
