import styled, { css } from 'styled-components';
import Arrow from '@components/svg-icons/arrow';
import { generateScales } from '@helpers/generate-media-queries';
import { hexToRgba } from '@helpers/hex-to-rgba';
import { defaultTheme } from '@styles/theme.styled';
import getColorMode, { VariantType } from '@helpers/get-color-mode';

export const DropdownContainer = styled.div<{ colorMode: VariantType['variant'] }>`
  ${({ theme, colorMode }) => {
    const { mobile, desktop } = theme.inputSize.lg;
    const { fontColor, accentColor } = getColorMode(colorMode);
    return css`
      position: relative;
      border-bottom: 1px solid ${theme.colors[fontColor]};
      background-color: transparent;
      color: ${theme.colors[accentColor]};
      width: 100%;
      ${generateScales('height', mobile.height, desktop.height, theme)}
      z-index: 1;
    `;
  }}
`;

export const StyledCircle = styled.div<{ $color: keyof typeof defaultTheme.colors }>`
  ${({ theme, $color }) => {
    return css`
      height: 1.75rem;
      width: 1.75rem;
      border: 1px solid ${theme.colors[$color]};
      border-radius: 0.875rem;
      display: flex;
      justify-content: center;
      align-items: center;
    `;
  }}
`;

export const StyledArrow = styled(Arrow)<{ isRotated: boolean }>`
  transform-origin: center;
  height: 0.375rem;
  width: auto;

  ${({ isRotated }) =>
    isRotated &&
    css`
      transform: rotate(180deg);
    `}
`;

export const Text = styled.span`
  ${({ theme }) => css`
    width: 100%;
    font-family: ${theme.fontFamily.text};
    font-weight: ${theme.fontWeights.normal};
    line-height: ${theme.lineHeights.sm};
    font-size: 1rem;
    text-decoration: none;
    border: none;
  `}
`;

export const ListItem = styled.li<{ colorMode: VariantType['variant'] }>`
  list-style: none;
  padding: 0.75rem 0.375rem;
  margin-bottom: 0;
  border-radius: 0.125rem;
  cursor: pointer;

  :not(:last-of-type) {
    padding-bottom: 0;
    margin-bottom: 0.25rem;
  }

  :hover {
    padding: 0.75rem 0.375rem;
    background-color: currentColor;
  }

  :hover + li {
    padding-top: 0;
  }

  :hover ${Text} {
    -webkit-filter: invert(100%);
    filter: invert(100%) grayscale(100%) contrast(100%);
    mix-blend-mode: luminosity;
  }
`;

export const DropDownList = styled.ul<{ colorMode: VariantType['variant']; $isOpen: boolean }>`
  ${({ theme, colorMode, $isOpen }) => {
    const { backgroundColor, fontColor } = getColorMode(colorMode);

    return css`
      margin-top: 0.8rem;
      padding: 0.375rem;
      border: 1px solid ${theme.colors[fontColor]};
      background-color: ${theme.colors[backgroundColor]};
      box-sizing: border-box;
      color: ${theme.colors[fontColor]};
      font-weight: 500;
      border-radius: 0.125rem;
      ${$isOpen ? `display: block;` : `display: none;`}
    `;
  }}
`;

interface ButtonProps {
  isVisible?: boolean;
  isSet: boolean;
  colorMode: VariantType['variant'];
}

export const Button = styled.button<ButtonProps>`
  ${({ theme, isSet, isVisible, colorMode }) => {
    const { mobile, desktop } = theme.inputSize.lg;
    const { backgroundColor, accentColor } = getColorMode(colorMode);

    return css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      width: 100%;
      height: 100%;
      text-align: left;
      line-height: 2.5rem;
      padding: 0;
      font-family: ${theme.fontFamily.text};
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize, theme)}
      background-color: transparent;
      cursor: pointer;
      color: ${isSet ? theme.colors[accentColor] : hexToRgba(theme.colors[accentColor], 0.66)};
      border: none;

      ${isVisible &&
      css`
        color: ${isSet ? theme.colors[backgroundColor] : theme.colors[accentColor]};
      `};
    `;
  }}
`;
