import React, { forwardRef } from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import { defaultColorMode } from '@constants/default-values';
import getColorMode, { VariantType } from '@helpers/get-color-mode';
import Text from '@components/text';
import ContactForm, { OptionType } from '@components/contact-form';
import Wrapper from '@components/wrapper.styled';
import Section from '@components/section';
import { transitionClasses } from '@helpers/animate';
import { TitleContainer, Container, StyledSubheading } from './contact-form-section.styled';

export interface ContactFormSectionProps {
  items: OptionType[];
  title?: string;
  description?: RichTextBlock[];
  colorMode?: VariantType['variant'];
  campaignId?: string;
  className?: string;
}

const ContactFormSection = forwardRef<HTMLElement, ContactFormSectionProps>(
  (
    { items, title, description, campaignId, className = '', colorMode = defaultColorMode },
    ref
  ): JSX.Element => {
    const { backgroundColor, fontColor, accentColor } = getColorMode(colorMode);

    return (
      <Section backgroundColor={backgroundColor} className={className} ref={ref}>
        <Wrapper id="contact-form">
          <Container>
            <TitleContainer>
              {title && (
                <Text
                  type="title"
                  stringText={title}
                  fontSize="heading3"
                  fontColor={fontColor}
                  className={transitionClasses.color.accentText}
                />
              )}
              {description && (
                <StyledSubheading
                  type="subheading"
                  richText={description}
                  fontSize="large"
                  fontColor={accentColor}
                  className={transitionClasses.color.text}
                />
              )}
            </TitleContainer>
            {!campaignId && (
              <ContactForm campaignId={campaignId} whyOptions={items} colorMode={colorMode} />
            )}
          </Container>
        </Wrapper>
      </Section>
    );
  }
);

export default ContactFormSection;
