import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { transitionClasses } from '@helpers/animate';
import { CookieConsentWrapper, CookieText, StyledButton } from './cookie.styled';

export interface CookieInterface {
  prismicGeneralConfig: {
    data: {
      cookie_text?: {
        richText: RichTextBlock[];
      };
      favicon?: {
        url?: string;
      };
    };
  };
}

const Cookie = (): JSX.Element => {
  const location = useLocation();

  const {
    prismicGeneralConfig: {
      data: { cookie_text },
    },
  }: CookieInterface = useStaticQuery(
    graphql`
      query CookieQuery {
        prismicGeneralConfig {
          ...GeneralConfigFragment
        }
      }
    `
  );

  return (
    <CookieConsentWrapper>
      <CookieConsent
        location="bottom"
        buttonId="test"
        buttonText="Got it"
        containerClasses={`consent-container ${transitionClasses.color.fill}`}
        contentClasses="consent-content"
        buttonWrapperClasses="consent-btn-wrapper"
        disableStyles
        declineButtonText="Decline"
        cookieName="gdpr-google-analytics"
        ButtonComponent={StyledButton}
        onAccept={() => {
          Cookies.set('gdpr-google-analytics', 'true', { expires: 365 });
          initializeAndTrack(location);
        }}
      >
        {!!cookie_text?.richText && (
          <CookieText type="description" richText={cookie_text?.richText} />
        )}
      </CookieConsent>
    </CookieConsentWrapper>
  );
};

export default Cookie;
