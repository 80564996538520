import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';
import { defaultColorMode } from '@constants/default-values';
import getColorMode, { VariantType } from '@helpers/get-color-mode';
import ContactForm, { OptionType } from '@components/contact-form';
import Text from '@components/text';
import { transitionClasses } from '@helpers/animate';
import {
  StyledModal,
  StyledWrapper,
  StyledSubheading,
  Container,
  TitleContainer,
} from './contact-modal.styled';

const query = graphql`
  query ContactQuery {
    allPrismicPage(filter: { uid: { eq: "contact" } }) {
      nodes {
        data {
          body {
            ...ContactFragment
          }
        }
      }
    }
  }
`;

interface ContactQuery {
  allPrismicPage: {
    nodes: {
      data: {
        body: {
          primary: {
            title?: {
              text: string;
            };
            description?: {
              richText: RichTextBlock[];
            };
            campaign_id?: string;
          };
          items: OptionType[];
        }[];
      };
    }[];
  };
}

interface ContactModalProps {
  colorMode?: VariantType['variant'];
  isOpen?: boolean;
}

const ContactModal = ({
  isOpen = false,
  colorMode = defaultColorMode,
}: ContactModalProps): JSX.Element => {
  const { backgroundColor, fontColor, accentColor } = getColorMode(colorMode);
  const {
    allPrismicPage: { nodes },
  }: ContactQuery = useStaticQuery(query);

  const {
    primary: { title, description, campaign_id },
    items,
  } = nodes[0].data.body[0];

  useEffect(() => {
    document.body.style.overflowY = isOpen ? 'hidden' : 'auto';
  }, [isOpen]);

  return (
    <StyledModal isVisible={isOpen} backgroundColor={backgroundColor} className="contact-modal">
      <StyledWrapper id="contact-form">
        <Container>
          <TitleContainer>
            {title && (
              <Text
                type="title"
                stringText={title.text}
                fontSize="heading3"
                fontColor={fontColor}
                className={`${transitionClasses.color.accentText}`}
              />
            )}
            {description && (
              <StyledSubheading
                type="subheading"
                richText={description.richText}
                fontSize="large"
                fontColor={accentColor}
                className={`${transitionClasses.color.text}`}
              />
            )}
          </TitleContainer>
          {!campaign_id && (
            <ContactForm campaignId={campaign_id || ''} whyOptions={items} colorMode={colorMode} />
          )}
        </Container>
      </StyledWrapper>
    </StyledModal>
  );
};

export default ContactModal;
