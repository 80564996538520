import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { generateScales } from '@helpers/generate-media-queries';
import { defaultTheme } from '@styles/theme.styled';

export const underlineTransition = css`
  // add an animated underline to text
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 0.15rem;
  transition: background-size 0.25s;
  text-decoration: none;
  :hover,
  :focus {
    background-size: 100% 0.15rem;
  }
`;

export const createTextLinkCSS = (
  theme: typeof defaultTheme,
  variant: keyof typeof defaultTheme.colors,
  $isAnimated?: boolean
) => {
  const { mobile, desktop } = theme.inputSize.lg;

  return css`
    display: inline;
    line-height: 1.5rem;
    font-weight: 400;
    font-family: ${theme.fontFamily.text};
    /* color: ${theme.colors[variant]}; */
    color: inherit;
    text-underline-offset: 0.15rem;
    ${generateScales('font-size', mobile.fontSize, desktop.fontSize, theme)};

    &:hover {
      text-decoration: none;
    }

    ${$isAnimated &&
    css`
      ${underlineTransition}
    `}
  `;
};

const calculateOffset = (borderRadius: string, height: string, arrowSize: number): number =>
  parseInt(borderRadius, 10) - (parseInt(height, 10) + arrowSize / 16) / 2;

const createButtonLinkCSS = (theme: typeof defaultTheme, $arrowSize: number) => {
  const { mobile, desktop } = theme.inputSize.lg;

  // offsets required to make inner x and y padding of the arrow svg equal
  const desktopOffset = calculateOffset(desktop.borderRadius, desktop.height, $arrowSize);
  const mobileOffset = calculateOffset(mobile.borderRadius, mobile.height, $arrowSize);

  return css`
    // layout
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    // typography
    color: ${theme.colors.primary};
    font-family: ${theme.fontFamily.text};
    ${generateScales('font-size', mobile.fontSize, desktop.fontSize, theme)}
    line-height: normal;
    font-weight: 400;
    text-decoration: none;
    // sizing
    height: 3.75rem;
    gap: 1rem;
    width: fit-content;
    ${generateScales('padding-left', mobile.borderRadius, desktop.borderRadius, theme)}
    ${generateScales('padding-right', mobile.borderRadius, desktop.borderRadius, theme)}
    ${generateScales('height', mobile.height, desktop.height, theme)}
    // appearance
    background-color: ${theme.colors.transparent};
    border: none;
    border-radius: ${theme.borderRadius.large};
    ${generateScales('border-radius', mobile.borderRadius, desktop.borderRadius, theme)}
    // states
    :hover {
      background-color: ${theme.colors.primaryLight};
    }
    // responsive
    @media (max-width: ${theme.breakpoints.sm}) {
      font-size: 1rem;
      padding: 0 2.25rem;
      height: 3.5rem;
    }
    // add offset to arrow
    svg {
      ${generateScales('margin-right', `${mobileOffset}rem`, `${desktopOffset}rem`, theme)}
    }
  `;
};

export const StyledLinkWrapper = styled.div<{ variant: keyof typeof defaultTheme.colors }>`
  ${({ theme, variant }) =>
    css`
      color: ${theme.colors[variant]};
    `}
`;

export const StyledTextLink = styled(Link)<{
  variant: keyof typeof defaultTheme.colors;
  $isAnimated: boolean;
}>`
  ${({ theme, variant, $isAnimated = false }) => createTextLinkCSS(theme, variant, $isAnimated)}
`;

export const ExternalTextLink = styled.a<{
  variant: keyof typeof defaultTheme.colors;
  $isAnimated: boolean;
}>`
  ${({ theme, variant, $isAnimated = false }) => createTextLinkCSS(theme, variant, $isAnimated)}
`;

interface ButtonLinkProps {
  $arrowSize: number;
}

export const StyledButtonLink = styled(Link)<ButtonLinkProps>`
  ${({ theme, $arrowSize }) => createButtonLinkCSS(theme, $arrowSize)}
`;

export const ExternalButtonLink = styled.a<ButtonLinkProps>`
  ${({ theme, $arrowSize }) => createButtonLinkCSS(theme, $arrowSize)}
`;

export const StyledButtonLabel = styled.span`
  line-height: 1rem;
`;
