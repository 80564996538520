import styled, { css } from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import { generateScalingQueries, generateScales } from '@helpers/generate-media-queries';

export const StyledEyebrow = styled.p<{
  fontSize: keyof typeof defaultTheme.fontSize;
  fontColor: keyof typeof defaultTheme.colors;
}>`
  ${({ theme, fontColor, fontSize }) => {
    const bottomMargin: string = theme.spacing.sm;

    return css`
      font-weight: 800;
      font-family: ${theme.fontFamily.text};
      font-size: ${theme.fontSize[fontSize].desktop};
      line-height: ${theme.lineHeights.xs};
      color: ${theme.colors[fontColor]};
      margin: 0 0 ${bottomMargin} 0;
      text-transform: uppercase;

      ${generateScales(
        'font-size',
        theme.fontSize[fontSize].mobile,
        theme.fontSize[fontSize].desktop,
        theme
      )}

      ${generateScalingQueries('margin-bottom', bottomMargin, theme)}
    `;
  }}
`;
