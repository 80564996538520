import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { defaultColorMode } from '@constants/default-values';
import handleSubmitForm from '@helpers/handle-form-submit';
import { validateEmail } from '@helpers/validate';
import { VariantType } from '@helpers/get-color-mode';
import { Form, ErrorBlock, InputContainer, StyledInput, StyledButton } from './sign-up-form.styled';

interface CallToActionProps {
  buttonLabel?: string;
  inputPlaceholder?: string;
  colorMode?: VariantType['variant'];
}

// Fill this with correct data from HubSpot
// Add a correct portalId and formId for HubSpot
const formId = 'b33b799a-ff7b-425c-b0b2-901b12d1ca29';

const SignUpForm = ({
  buttonLabel = 'Sign up',
  inputPlaceholder = 'Email address',
  colorMode = defaultColorMode,
}: CallToActionProps): JSX.Element => {
  const [status, setStatus] = useState('ready');
  const [email, setEmail] = useState('');
  const [disabledState, setDisabledState] = useState(true);
  const [errors, setErrors] = useState(['email']);

  const fields = [
    {
      name: 'email',
      value: email,
    },
  ];

  const onEmailInputChange = (currentValue: string) => {
    setEmail(currentValue);
    setStatus('ready');
  };

  const handleSubmit = () => {
    handleSubmitForm(fields, formId, setStatus)
      .then(() => navigate('/confirmation'))
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    setDisabledState(!!errors.length);
  }, [errors.length]);

  return (
    <>
      {status === 'error' && (
        <ErrorBlock>Whoops! Something went wrong. Please try again</ErrorBlock>
      )}
      <Form>
        <InputContainer>
          <StyledInput
            name="email"
            type="email"
            value={email}
            onChange={onEmailInputChange}
            placeholder={inputPlaceholder}
            isRequired
            validate={validateEmail}
            errors={errors}
            setErrors={setErrors}
            colorMode={colorMode}
          />
        </InputContainer>
        <StyledButton
          variant={colorMode}
          label={buttonLabel}
          onClick={handleSubmit}
          disabled={disabledState}
          outlined
          size="medium"
        />
      </Form>
    </>
  );
};

export default SignUpForm;
