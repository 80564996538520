import styled, { css } from 'styled-components';
import Text from '@components/text';
import { generateScales } from '@helpers/generate-media-queries';
import { wrapperWidths, wrapperPadding } from '@components/wrapper.styled';
import { StyledMediumButton } from '@components/button/button.styled';

export const CookieText = styled(Text)`
  ${({ theme }) => css`
    display: block;
    color: ${theme.colors.primary};
    font-size: 1rem;
    font-family: ${theme.fontFamily.text};
    opacity: 1;

    a {
      color: ${theme.colors.primary};
      text-decoration: underline;
      text-underline-offset: 0.125rem;
    }
  `}
`;

export const CookieConsentWrapper = styled.div`
  ${({ theme }) => css`
    .consent-container {
      line-height: ${theme.lineHeights.lg};
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 2rem;
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
      width: ${wrapperWidths.xxl};
      margin: 0 auto 1rem auto;
      padding: 1.5rem 1rem;
      background: ${theme.colors.white};
      z-index: 999;
      font-family: ${theme.fontFamily.display};
      font-size: 1rem;
      border-radius: 0.5rem;
      font-weight: 400;
      box-shadow: -0.25rem -0.5rem 2rem rgba(0, 0, 0, 0.2);

      @media only screen and (max-width: ${theme.breakpoints.xxl}) {
        width: ${wrapperWidths.xxl};
      }

      @media only screen and (max-width: ${theme.breakpoints.xl}) {
        width: ${wrapperWidths.xl};
      }

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        width: ${wrapperWidths.lg};
      }

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        width: ${wrapperWidths.md};
        flex-direction: column;
        justify-items: flex-start;
      }

      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        width: calc(100% - 2rem);
        padding: 0.75rem 0.25rem;
      }
    }

    .consent-btn-wrapper {
      display: flex;
      column-gap: 0.5rem;

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        width: 100%;
        padding: 0 ${wrapperPadding};
        margin-top: 1.5rem;
      }
    }

    .consent-content {
      padding: 0 ${wrapperPadding};
    }
  `}
`;

export const StyledButton = styled(StyledMediumButton)`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    border-color: ${theme.colors.primary};
    color: ${theme.colors.primary};
    font-weight: 500;
    white-space: nowrap;
    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop, theme)}

    &:hover {
      background: ${theme.colors.primary};
      border-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }
  `}
`;
