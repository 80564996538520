import React, { MouseEvent } from 'react';
import { VariantType } from '@helpers/get-color-mode';
import { transitionClasses } from '@helpers/animate';
import { StyledSmallButton, StyledMediumButton, StyledLargeButton } from './button.styled';

export interface ButtonProps {
  outlined?: boolean;
  variant?: VariantType['variant'];
  size?: 'small' | 'medium';
  type?: 'button' | 'submit' | 'reset';
  label?: string;
  disabled?: boolean;
  onClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

const Button = ({
  outlined = false,
  variant,
  size,
  type,
  label,
  onClick,
  disabled = false,
  className = '',
}: ButtonProps): JSX.Element | null => {
  const buttonParams = {
    outlined,
    variant,
    type,
    onClick,
    className: `${transitionClasses.color.border} ${transitionClasses.color.text} ${className}`,
    disabled,
  };

  switch (size) {
    case 'small':
      return (
        <StyledSmallButton {...buttonParams}>
          <span>{label}</span>
        </StyledSmallButton>
      );
    case 'medium':
      return (
        <StyledMediumButton {...buttonParams}>
          <span>{label}</span>
        </StyledMediumButton>
      );
    default:
      return (
        <StyledLargeButton {...buttonParams}>
          <span>{label}</span>
        </StyledLargeButton>
      );
  }
};

export default Button;
