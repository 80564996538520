import React, { ChangeEvent, useState, Dispatch } from 'react';
import { defaultColorMode } from '@constants/default-values';
import { defaultValidate, updateErrors } from '@helpers/validate';
import getColorMode, { VariantType } from '@helpers/get-color-mode';
import { transitionClasses } from '@helpers/animate';
import { HelperText } from '@components/contact-form/contact-form.shared.styled';
import SmallInput from './input-small/input-small.styled';
import LargeInput from './input-large/input-large.styled';

export interface InputProps {
  type: string;
  size?: 'small';
  placeholder?: string;
  onChange?: (value: string) => void;
  className?: string;
  value?: string;
  name: string;
  isRequired?: boolean;
  validate?: (value: string) => boolean;
  errors?: string[];
  setErrors?: Dispatch<React.SetStateAction<string[]>>;
  colorMode?: VariantType['variant'];
}

const Input = ({
  placeholder,
  size,
  className = '',
  type,
  onChange,
  value = '',
  name,
  isRequired = false,
  validate,
  errors = [],
  setErrors,
  colorMode = defaultColorMode,
}: InputProps): JSX.Element | null => {
  const [isWarningDisplayed, setIsWarningDisplayed] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!onChange) {
      return null;
    }
    setIsWarningDisplayed(false);
    return onChange(e.target.value);
  };

  const handleBlur = () => {
    if (!isRequired) return;

    const options = { value, validate, setIsWarningDisplayed };
    const isValid = defaultValidate(options);

    if (setErrors) {
      updateErrors(name, isValid, errors, setErrors);
    }
  };

  const formattedName =
    name
      .split(/(?=[A-Z])/)
      .join(' ')
      .toLowerCase() || 'data';

  const renderValidationWarning = (): JSX.Element => {
    const { fontColor } = getColorMode(colorMode);

    return (
      <HelperText
        $color={fontColor}
        className={`${transitionClasses.color.accentText}`}
        $isVisible={isWarningDisplayed}
      >
        Please enter a <u>{type === 'email' ? 'valid email' : formattedName}</u>
      </HelperText>
    );
  };

  const inputParams = {
    type,
    placeholder,
    onChange: handleChange,
    className: `${transitionClasses.color.border} ${transitionClasses.color.text} ${className}`,
    value,
    name,
    onBlur: handleBlur,
    colorMode,
  };

  switch (size) {
    case 'small':
      return (
        <>
          {renderValidationWarning()}
          <SmallInput {...inputParams} />
        </>
      );
    default:
      return (
        <>
          {renderValidationWarning()}
          <LargeInput {...inputParams} />
        </>
      );
  }
};

export default Input;
