import styled, { css } from 'styled-components';
import Input from '@components/input';
import Button from '@components/button';

export const Form = styled.div`
  ${({ theme }) => css`
    display: flex;
    min-height: 5.38rem;
    align-items: flex-end;
    gap: 2rem;

    @media (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      align-items: center;
    }
  `}
`;

export const ErrorBlock = styled.div`
  margin-bottom: '1rem';
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
  align-items: stretch;
`;

export const StyledInput = styled(Input)`
  margin-top: 0.2rem;
  max-height: 3.82rem;
`;

export const StyledButton = styled(Button)`
  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.md}) {
      width: 100%;
    }
  `}
`;
