import styled from 'styled-components';
import { Button, LargeButton, MediumButton, SmallButton } from '@styles/button.styled';

export const StyledButton = styled.button`
  ${Button}
`;

export const StyledSmallButton = styled.button`
  ${SmallButton}
`;

export const StyledMediumButton = styled.button`
  ${MediumButton}
`;

export const StyledLargeButton = styled.button`
  ${LargeButton}
`;
