import React from 'react';

interface FieldsType {
  name: string;
  value: string;
}

const portalId = '21261187';

const handleFormSubmit = async (
  fields: FieldsType[],
  formId: string,
  setStatus: React.Dispatch<React.SetStateAction<string>>
) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

    const data = {
      submittedAt: Date.now(),
      fields,
      context: {
        pageUri: 'subscribe-success',
        pageName: 'Subscribe successful',
      },
    };

    const finalData = JSON.stringify(data);
    xhr.open('POST', url);

    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          setStatus('error');
          reject(new Error(xhr.statusText));
        }
      }
    };

    xhr.send(finalData);
  });
};

export default handleFormSubmit;
