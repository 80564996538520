import styled, { css } from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import { generateScales } from '@helpers/generate-media-queries';
import { createTextLinkCSS } from '@components/link/link.styled';

export const StyledSubheading = styled.div<{
  fontSize: keyof typeof defaultTheme.fontSize;
  fontColor: keyof typeof defaultTheme.colors;
}>`
  ${({ theme, fontSize, fontColor }) => css`
    font-family: ${theme.fontFamily.text};
    font-size: ${theme.fontSize[fontSize].desktop};
    font-weight: ${theme.fontWeights.normal};
    line-height: ${theme.lineHeights.lg};
    color: ${theme.colors[fontColor]};

    ${generateScales(
      'font-size',
      theme.fontSize[fontSize].mobile,
      theme.fontSize[fontSize].desktop,
      theme
    )}

    p + p {
      ${generateScales(
        'margin-top',
        theme.fontSize[fontSize].mobile,
        theme.fontSize[fontSize].desktop,
        theme
      )}
    }

    a {
      ${() => createTextLinkCSS(theme, fontColor)}

      &::after {
        display: none;
      }
    }
  `}
`;
