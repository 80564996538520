import styled from 'styled-components';

export const wrapperPadding = '1rem';
const paddingLeftRight = 1;
export const maxWidthLg = 73.125 + 2 * paddingLeftRight;
export const wrapperWidths = {
  xxl: '1256px',
  xl: '960px',
  lg: '720px',
  md: '540px',
  sm: '100',
};

export const Wrapper = styled.div<{
  widthSize?: 'sm' | 'md' | 'lg';
  alignment?: 'left' | 'center';
}>`
  ${({ theme, widthSize = 'lg', alignment = 'left' }) => `
    margin: 0 auto;
    width: 100%;
    max-width: ${wrapperWidths.xxl};
    padding: 0 ${wrapperPadding};

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      max-width: ${wrapperWidths.xxl};
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      max-width: ${wrapperWidths.xl};
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      max-width: ${wrapperWidths.lg};
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      max-width: ${wrapperWidths.md};
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      max-width: ${wrapperWidths.sm};
    }

    ${
      alignment === 'center'
        ? `
            justify-content: center;
          `
        : ``
    }

    ${
      widthSize === 'md'
        ? `
        width: 1056px;

        @media (max-width: ${theme.breakpoints.xxl}) {
          width: 1026px;
        }

        @media only screen and (max-width: ${theme.breakpoints.xl}) {
          max-width: 960px;
        }
    
        @media only screen and (max-width: ${theme.breakpoints.lg}) {
          max-width: 720px;
        }
    
        @media only screen and (max-width: ${theme.breakpoints.md}) {
          max-width: 540px;
        }
    
        @media only screen and (max-width: ${theme.breakpoints.sm}) {
          max-width: 100%;
        }
      `
        : ''
    }
  `}
`;

export default Wrapper;
